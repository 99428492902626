import {useCallback, useEffect, useState} from 'react'
import {message} from 'antd'

import Icon from '../../../../components/Icon'
import {storeApi} from '../../../../services/api'
import SelectItems from '../SelectItems'

import styles from './BucketModal.module.scss'

export enum ModalAction {
  NEW_BUCKET = 'NEW_BUCKET',
  EDIT_BUCKET = 'EDIT_BUCKET',
}

export const MODAL_DEFAULTS = {
  title: {
    [ModalAction.NEW_BUCKET]: 'Add Bucket',
    [ModalAction.EDIT_BUCKET]: 'Edit Bucket',
  },
  linkText: {
    [ModalAction.NEW_BUCKET]: 'Link',
    [ModalAction.EDIT_BUCKET]: 'New Link',
  },
  btnText: {
    [ModalAction.NEW_BUCKET]: 'Add',
    [ModalAction.EDIT_BUCKET]: 'Save',
  },
  toast: {
    content: {
      [ModalAction.NEW_BUCKET]: 'Bucket added successfully',
      [ModalAction.EDIT_BUCKET]: 'Bucket updated successfully',
    },
    icon: {
      [ModalAction.NEW_BUCKET]: <Icon name="check_mark" isConstructed size="16px" fill="#fff" />,
      [ModalAction.EDIT_BUCKET]: <Icon name="check_mark" isConstructed size="16px" fill="#fff" />,
    },
  },
}

type BucketModalProps = {
  buckets: {
    id: string
    name: string
    buckets: any[]
  }[]
  action: ModalAction
  setVisibleModal: (value: boolean) => void
  handleUpdateBuckets: () => void
  page: number
  shownUserAddress: string
  nftSelected?: any
}

const BucketModal: React.FC<BucketModalProps> = ({
  buckets,
  action,
  setVisibleModal,
  handleUpdateBuckets,
  page,
  shownUserAddress,
  nftSelected,
}) => {
  const [selectedNFT, setSelectedNFT] = useState()
  const [bucketsOptions, setBucketsOptions] = useState([{value: '', label: ''}])
  const [linkValue, setLinkValue] = useState('')
  const [selectedBucket, setSelectedBucket] = useState<any>()
  const [nftOptions, setNftOptions] = useState([])

  useEffect(() => {
    if (action === ModalAction.EDIT_BUCKET && nftSelected) {
      setSelectedNFT(nftSelected.value)
      const associatedBuckets = buckets.find(bucket => bucket.id === selectedNFT)
      const selectedBucketOptions = associatedBuckets?.buckets.map(({id, link}) => ({
        value: id,
        label: link,
      }))
      if (selectedBucketOptions) setBucketsOptions(selectedBucketOptions)
    }
  }, [selectedNFT, nftSelected, action, buckets])

  const handleOnChangeNFT = (selectedOption: any) => {
    setSelectedNFT(selectedOption.value)
  }

  const addBucket = useCallback(async (link: string, id: number) => {
    storeApi.addBucket(link, id)
  }, [])

  const updateBucket = useCallback(async (link: string, id: number) => {
    storeApi.updateBucket(link, id)
  }, [])

  const fetchNftItems = useCallback(async () => {
    const {items} = (await storeApi.getCreated(shownUserAddress, page)).data
    const nftOptionsResult = items.map(({id, name}: any) => ({
      value: id,
      label: name,
    }))
    setNftOptions(nftOptionsResult)
  }, [shownUserAddress, page])

  useEffect(() => {
    if (nftSelected) return
    fetchNftItems()
  }, [fetchNftItems, nftSelected])

  const handleOnChangeBucket = (selectedOption: any) => {
    setSelectedBucket(selectedOption)
  }

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (linkValue && selectedNFT) {
      if (action === ModalAction.EDIT_BUCKET) {
        updateBucket(linkValue, selectedBucket?.value)
      }
      if (action === ModalAction.NEW_BUCKET) {
        addBucket(linkValue, selectedNFT)
      }
    }
    handleToast()
    setVisibleModal(false)
    handleUpdateBuckets()
  }

  const handleToast = () => {
    message.open({
      content: MODAL_DEFAULTS.toast.content[action],
      icon: MODAL_DEFAULTS.toast.icon[action],
      duration: 1.5,
      className: styles.toast,
    })
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{MODAL_DEFAULTS.title[action]}</h4>
      <form>
        {action === ModalAction.NEW_BUCKET && (
          <div className={styles.formRow}>
            <span className={styles.formRowLabel}>NFT</span>
            <SelectItems
              placeholder="Select NFT"
              options={nftOptions}
              value={undefined}
              onChange={handleOnChangeNFT}
            />
          </div>
        )}
        {action === ModalAction.EDIT_BUCKET && (
          <>
            <div className={styles.formRow}>
              <span className={styles.formRowLabel}>NFT</span>
              <SelectItems
                placeholder="Select NFT"
                options={nftOptions}
                value={nftSelected}
                onChange={handleOnChangeNFT}
                isDisabled
              />
            </div>
            <div className={styles.formRow}>
              <span className={styles.formRowLabel}>Buckets</span>
              <SelectItems
                placeholder="Select bucket"
                options={bucketsOptions}
                onChange={handleOnChangeBucket}
              />
            </div>
          </>
        )}
        <div className={styles.formRow}>
          <span className={styles.formRowLabel}>{MODAL_DEFAULTS.linkText[action]}</span>
          <input
            type="text"
            id="bucketLink"
            name="bucketLink"
            className={styles.bucketLink}
            placeholder={linkValue}
            onChange={e => setLinkValue(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <button type="submit" className={styles.submitButton} onClick={handleOnClick}>
            {MODAL_DEFAULTS.btnText[action]}
          </button>
        </div>
      </form>
    </div>
  )
}

export default BucketModal
