import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getTrackBackground, Range} from 'react-range'
import cn from 'classnames'

import {ReactComponent as Cross} from '../../assets/img/icons/cross-gradient.svg'
import Card from '../../components/Card'
import CardSkeleton from '../../components/Card/Skeleton/CardSkeleton'
import Checkbox from '../../components/Checkbox'
import Dropdown from '../../components/Dropdown'
import EmptyState from '../../components/EmptyState/EmptyState'
import Icon from '../../components/Icon'
import NavLink from '../../components/NavLink/NavLink'
import {ADVANCED_SEARCH_ITEMS_PER_PAGE} from '../../constants/constants'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import {storeApi} from '../../services/api'
import {NavLinkType} from '../../types/navLink'
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter'
import {getAllCoinNames, getCoinNames} from '../../utils/coins'

import styles from './AdvancedSearch.module.scss'

const AdvancedSearch: React.FC = () => {
  const {t} = useTranslation()
  const [bids, setBids] = useState([])
  const [page, setPage] = useState<number>(1)
  const [allPages, setAllPages] = useState<number>(1)
  const [time, setTime] = useState<any>(null)
  const [searchTime, setSearchTime] = useState<any>(null)
  const [navLinks, setNavLinks] = useState<NavLinkType[]>([])
  const currencyOptions = useCallback(() => {
    if (!localStorage.kephi_nft_chainName) return [t('all')].concat(getAllCoinNames())
    return [t('all')].concat(getCoinNames())
  }, [t])
  const [search, setSearch] = useState('')
  const [isRateHovering, setIsRateHovering] = useState<boolean>(false)
  const [maxPrice, setMaxPrice] = useState<number>(0)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  const fetchMoreItems = useCallback(() => {
    if (isLoading || isLoadingMore || page === allPages) return
    setPage(page + 1)
    setIsFetching(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, page, isLoading, isLoadingMore])
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems)

  const dateOptions = useCallback(
    () => [
      t('home.discover.filter.recently_added'),
      t('home.discover.filter.long_added'),
      t('home.discover.filter.highest_price'),
      t('home.discover.filter.the_lowest_price'),
      t('home.discover.filter.most_liked'),
      t('home.discover.filter.least_liked'),
    ],
    [t],
  )
  const creatorOptions = useCallback(
    () => [
      t('creatorOption.all'),
      t('creatorOption.verifiedOnly'),
      t('creatorOption.unverifiedOnly'),
    ],
    [t],
  )

  const [queries, setQueries] = useState({
    type: 'items',
    on_sale: true,
    is_verified: creatorOptions()[0],
    order_by: dateOptions()[0],
    tags: t('allItems'),
    max_price: maxPrice,
    currency: currencyOptions()[0],
  })

  const fetchMaxPrice = useCallback((currency: string) => {
    storeApi.getMaxPrice(currency).then(({data}: any) => {
      setQueries((prevState: any) => ({
        ...prevState,
        max_price: [data.max_price],
      }))
      setMaxPrice(data.max_price)
    })
  }, [])

  const fetchTags = useCallback(async () => {
    const links = await storeApi.getTags()
    setNavLinks(
      [{label: t('allItems')}].concat(
        links.data.tags.map((tag: string) => {
          return {
            label: capitalizeFirstLetter(tag),
          } as NavLinkType
        }),
      ),
    )
    setQueries((prevState: any) => ({
      ...prevState,
      tags: t('allItems'),
    }))
  }, [t])

  const fetchSearch = useCallback((queriesObject: any, searchStr: string) => {
    setLoading(true)
    storeApi
      .getSearchResults(
        {
          text: searchStr,
          page: 1,
          itemsPerPage: ADVANCED_SEARCH_ITEMS_PER_PAGE,
        },
        queriesObject,
      )
      .then(({data}) => {
        setBids(data.items)
        setAllPages(Math.ceil(data.total_tokens / ADVANCED_SEARCH_ITEMS_PER_PAGE))
      })
      .finally(() => setLoading(false))
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    fetchSearch(queries, search)
  }

  const handleChange = (key: string, value: any) => {
    setQueries((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
    if (key === 'max_price') {
      if (time) {
        clearTimeout(time)
        setTime(setTimeout(() => fetchSearch({...queries, [key]: value}, search), 2000))
      } else {
        setTime(setTimeout(() => fetchSearch({...queries, [key]: value}, search), 2000))
      }
    } else {
      fetchSearch({...queries, [key]: value}, search)
    }
  }

  const handleReset = () => {
    setQueries((prevState: any) => ({
      ...prevState,
      type: 'items',
      on_sale: true,
      is_verified: creatorOptions()[0],
      order_by: dateOptions()[0],
      tags: t('allItems'),
      max_price: maxPrice,
      currency: currencyOptions()[0],
    }))
    setSearch('')
    fetchSearch(
      {
        ...queries,
        type: 'items',
        on_sale: true,
        is_verified: creatorOptions()[0],
        order_by: dateOptions()[0],
        tags: navLinks[0]?.label,
        max_price: [maxPrice],
        currency: currencyOptions()[0],
      },
      '',
    )
  }

  const fetchSearchPage = useCallback(() => {
    setIsLoadingMore(true)
    storeApi
      .getSearchResults({text: search, page, itemsPerPage: ADVANCED_SEARCH_ITEMS_PER_PAGE}, queries)
      .then(({data}) => {
        setBids(bids.concat(data.items))
      })
      .finally(() => setIsLoadingMore(false))
    // eslint-disable-next-line
  }, [page]);

  const handleLoadMore = useCallback(() => {
    if (page <= allPages) fetchSearchPage()
    else setIsFetching(false)
  }, [page, allPages, fetchSearchPage, setIsFetching])

  useEffect(() => {
    if (searchTime) {
      clearTimeout(searchTime)
      setSearchTime(setTimeout(() => fetchSearch(queries, search), 2500))
    } else {
      setSearchTime(setTimeout(() => fetchSearch(queries, search), 0))
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      handleLoadMore()
    }
  }, [handleLoadMore, page])

  useEffect(() => {
    fetchTags()
    fetchMaxPrice(queries.currency)
  }, [fetchTags, fetchMaxPrice, queries.currency])

  useEffect(() => {
    if (
      currencyOptions()[0] !== 'all' ||
      dateOptions()[0] !== 'home.discover.filter.recently_added' ||
      creatorOptions()[0] !== 'creatorOption.all'
    ) {
      setQueries(prev => ({
        ...prev,
        order_by: dateOptions()[0],
        currency: currencyOptions()[0],
        is_verified: creatorOptions()[0],
      }))
    }
  }, [currencyOptions, dateOptions, creatorOptions, t])

  // const STEP = 0.1
  const STEP = useMemo(() => {
    if (!maxPrice) {
      return 0.001
    }
    if (maxPrice < 100) {
      return 0.01
    }
    if (maxPrice < 1000) {
      return 10
    }
    if (maxPrice < 10000) {
      return 100
    }
    const len = maxPrice.toString().length
    const s = +(maxPrice / 2 ** len).toFixed(0)
    return s
  }, [maxPrice])
  const MIN = 0
  // const MAX = 10

  const cardSkeletons = Array.from(
    {length: ADVANCED_SEARCH_ITEMS_PER_PAGE},
    (_value, index) => index,
  )

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.top}>
          <div className={styles.title}>{t('advancedSearch.typeYourKeywords')}</div>
          <form className={styles.search} action="" onSubmit={e => handleSubmit(e)}>
            <input
              className={styles.input}
              type="text"
              value={search}
              autoComplete="off"
              onChange={e => {
                setSearch(e.target.value)
              }}
              name="search"
              placeholder={t('advancedSearch.search')}
              required
            />
            <button type="submit" className={styles.result}>
              <Icon name="search" size="18" />
            </button>
          </form>
        </div>
        <div className={styles.sorting}>
          <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={queries.order_by}
              setValue={(value: any) => handleChange('order_by', value)}
              options={dateOptions()}
            />
          </div>
          <NavLink
            className={styles.nav}
            navLinks={navLinks}
            onClick={(value: any) => handleChange('tags', value)}
            activeLink={queries.tags}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.filters}>
            <Checkbox
              className={styles.checkbox}
              content={t('advancedSearch.filter.showPlacedBidsOnly')}
              value={queries.on_sale}
              onChange={() => handleChange('on_sale', !queries.on_sale)}
            />
            {!!maxPrice && (
              <div className={styles.range}>
                <div className={styles.label}>{t('advancedSearch.filter.priceRange')}</div>
                <Range
                  values={[queries.max_price]}
                  step={STEP}
                  min={MIN}
                  max={maxPrice}
                  onChange={value => handleChange('max_price', value)}
                  renderTrack={({props, children}) => (
                    <div
                      role="button"
                      onKeyDown={() => {}}
                      tabIndex={0}
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: '36px',
                        display: 'flex',
                        width: '100%',
                      }}>
                      <div
                        ref={props.ref}
                        style={{
                          height: '8px',
                          width: '100%',
                          borderRadius: '4px',
                          background: getTrackBackground({
                            values: [queries.max_price],
                            colors: ['#eb8948', '#E6E8EC'],
                            min: MIN,
                            max: maxPrice,
                          }),
                          alignSelf: 'center',
                          cursor: 'auto',
                        }}>
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({props}) => (
                    <div
                      {...props}
                      style={{
                        // ...props.style,
                        height: '24px',
                        width: '24px',
                        borderRadius: '50%',
                        background: 'linear-gradient(90deg, #eb8948 0%, #f47622 100%)',
                        border: '3px solid #FCFCFD',
                        display: 'inline-block',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      tabIndex={0}
                      role="slider"
                      data-handle="0"
                      aria-orientation="horizontal"
                      aria-valuemin={MIN}
                      aria-valuemax={maxPrice}
                      aria-valuenow={queries.max_price}
                      onMouseEnter={() => !isRateHovering && setIsRateHovering(true)}
                      onMouseLeave={() => isRateHovering && setIsRateHovering(false)}>
                      <div
                        style={{
                          display: isRateHovering ? 'flex' : 'none',
                          justifyContent: 'center',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          position: 'absolute',
                          textAlign: 'center',
                          top: '-28px',
                          transform: 'translate(-50%, 0)',
                          left: '50%',
                          width: 'fit-content',
                          color: '#fff',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '14px',
                          padding: '4px 5px',
                          borderRadius: '8px',
                          backgroundColor: 'rgb(20 20 22 / 77%)',
                          cursor: 'pointer',
                          transition: 'none',
                        }}>
                        {`${queries.max_price} ${queries.currency?.toLocaleUpperCase()}`}
                      </div>
                    </div>
                  )}
                />
                <div className={styles.scale}>
                  <div className={styles.number}>
                    {MIN} {queries.currency?.toLocaleUpperCase()}
                  </div>
                  <div className={styles.number}>
                    {+maxPrice} {queries.currency?.toLocaleUpperCase()}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.group}>
              <div className={styles.item}>
                <div className={styles.label}>{t('advancedSearch.filter.creator')}</div>
                <Dropdown
                  className={styles.dropdown}
                  value={queries.is_verified}
                  setValue={(value: any) => handleChange('is_verified', value)}
                  options={creatorOptions()}
                />
              </div>
              <div className={styles.item}>
                <div className={styles.label}>{t('advancedSearch.filter.currency')}</div>
                <Dropdown
                  className={styles.dropdown}
                  value={queries.currency}
                  setValue={(value: any) => handleChange('currency', value)}
                  options={currencyOptions()}
                />
              </div>
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              onClick={handleReset}
              className={styles.reset}>
              <Cross />
              <span>{t('advancedSearch.filter.resetFilter')}</span>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {!!bids.length &&
                (!isLoading || isLoadingMore || isFetching) &&
                bids.map((bid: any) => <Card className={styles.card} item={bid} key={bid.id} />)}
              {!bids.length && !isLoading && <EmptyState />}
              {(isLoading || isLoadingMore) &&
                cardSkeletons.map(cardSkeleton => (
                  <CardSkeleton className={styles.card} key={cardSkeleton} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSearch
