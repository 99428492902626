import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import WertModule from '@wert-io/module-react-component'
import WertWidget from '@wert-io/widget-initializer'
import {signSmartContractData} from '@wert-io/widget-sc-signer'
import {message, notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'
import {ethers} from 'ethers'
import useDarkMode from 'use-dark-mode'
import {v4 as uuidv4} from 'uuid'

import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import LoaderCircle from '../../../../components/LoaderCircle'
import Modal from '../../../../components/Modal'
import TextInput from '../../../../components/TextInput'
import {PROVIDER} from '../../../../constants/constants'
import {TOKEN_STATUS} from '../../../../constants/tokenStatus'
import {storeApi} from '../../../../services/api'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {Web3Wallet} from '../../../../services/wallets/web3Wallet'
import {Wallet} from '../../../../services/walletService'
import {rootStore, useMst} from '../../../../store/store'
import {IPaymentStatus, PAYMENT_STATUS} from '../../../../types/wert'
import {formatCoinToken, getCoinDecimalsByName} from '../../../../utils/coins'
import Config from '../../../../utils/config'
import {getContractType} from '../../../../utils/contracts'
import {getBalanceByCoinName} from '../../../../utils/getBalance'
import myLocalStorage from '../../../../utils/myLocalStorage'
import {shorterAddress} from '../../../../utils/shorterAddress'

import styles from './Checkout.module.scss'

message.config({
  top: 85,
})

interface ICheckoutProps {
  className?: string
  tokenId: number
  standart: string
  sellerId: string | number
  title: string
  media: string
  format: string
  creatorName: string
  fee: number
  price: number
  currency: string
  setIsSuccess: (value: boolean) => void
  close: () => void
  isVerified: boolean
  setTransactionHash: (value: boolean) => void
  maximum?: number
  isMultiChainPurchase?: boolean
  isCreditCardPurchase?: boolean
  network: {
    name: string
    native_symbol: string
  }
}

const MAX_WIDTH_MOBILE = 470

const Checkout: React.FC<ICheckoutProps> = ({
  className,
  tokenId,
  standart,
  sellerId,
  price,
  fee,
  currency,
  title,
  media,
  format,
  creatorName,
  setIsSuccess,
  close,
  isVerified,
  setTransactionHash,
  maximum,
  isMultiChainPurchase,
  isCreditCardPurchase,
  network,
}) => {
  const {t} = useTranslation()
  const {value: isDarkMode} = useDarkMode(true)
  const walletConnector = useWalletConnectorContext()
  const {user} = useMst()
  const [balance, setBalance] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState('1')
  const [rubicSrc, setRubicSrc] = useState('')
  const [rubicModalVisible, setRubicModalVisible] = useState(true)
  const [wertOptions, setWertOptions] = useState<WertWidget['options']>()
  const [wertModalVisible, showWertModal] = useState<boolean>(false)
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )
  const [isAllowed, setIsAllowed] = useState(false)
  const [isApproving, setApproving] = useState<boolean>(false)

  const getUserBalance = useCallback(async () => {
    const req = walletConnector.walletService.getTokenBalance(formatCoinToken(currency))

    req.then((data: string | number) => {
      setBalance(getBalanceByCoinName(data, currency))
    })
  }, [walletConnector.walletService, currency])

  const onPaymentStatusChange = useCallback(
    (paymentStatus: IPaymentStatus) => {
      if (
        paymentStatus.status === PAYMENT_STATUS.PENDING ||
        paymentStatus.status === PAYMENT_STATUS.PROGRESS
      ) {
        storeApi.trackMinerTransaction(tokenId, '', '', 'Buy_web2')
        myLocalStorage.set(
          `${shorterAddress(user.address, 8)}_${TOKEN_STATUS.EXCHANGE_PENDING}_${tokenId}`,
          true,
        )
        setIsSuccess(true)
        close()
      }
      if (
        paymentStatus.status === PAYMENT_STATUS.FAILED ||
        paymentStatus.status === PAYMENT_STATUS.FAILOVER
      ) {
        setIsSuccess(false)
        close()
        message.error({
          content: t('wert.paymentError'),
          className: styles.errorMessage,
        })
      }
    },
    [close, setIsSuccess, t, tokenId, user.address],
  )

  const checkoutCreditCard = useCallback(
    async (data: any): Promise<any> => {
      try {
        /* Start - Testing Wert */
        // Get user address
        const userAccount: any = await walletConnector.walletService.getAccount()
        const userAddress = userAccount.address
        // Encode the call to mintNFT(address = userAddress, numberOfTokens = 1)

        const sc_input_data = new ethers.utils.Interface(
          rootStore.contracts.params.BEP20[getContractType()].abi,
        ).encodeFunctionData(data.initial_tx.method, [
          data.initial_tx.data.idOrder,
          data.initial_tx.data.SellerBuyer,
          data.initial_tx.data.tokenToBuy,
          data.initial_tx.data.tokenToSell,
          data.initial_tx.data.fee.feeAddresses,
          [
            new BigNumber(data.initial_tx.data.fee.feeAmounts[0]).toFixed(0).toString(),
            new BigNumber(data.initial_tx.data.fee.feeAmounts[1]).toFixed(0).toString(),
          ],
          data.initial_tx.data.signature,
        ])

        // Create signed SC data for wert-widget
        // Please do this on backend

        const signedData: WertWidget['options'] = signSmartContractData(
          {
            address: userAddress, // user's address
            commodity: currency.toLocaleUpperCase(),
            commodity_amount: price * +amount, // the crypto amount that should be send to the contract method
            pk_id: 'key1', // always 'key1'
            // sc_address: '0x24a354CDc1306a2D2cd99DDb686E13aC701814a5', // your SC address
            sc_address: rootStore.contracts.params.EXCHANGE[getContractType()].address,
            sc_id: uuidv4(), // must be unique for any request
            sc_input_data,
          },
          process.env.REACT_APP_PRIVATE_KEY ||
            '0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3',
        )

        let otherWidgetOptions: WertWidget['options'] = {
          partner_id: process.env.REACT_APP_PARTNER_ID, // your partner id
          click_id: uuidv4(), // unique id of purhase in your system
          width: isMobileWidthResolution ? window.innerWidth - 90 : 470,
          height: 670,
          theme: 'dark',
          color_background: '#1E1F25',
          listeners: {
            'close': (closeData: any) => console.log('close', closeData),
            'errors': (errorData: {name: string; message: string}) => {
              message.error({
                content: errorData.message,
                className: styles.errorMessage,
              })
            },
            'payment-status': (paymentStatus: IPaymentStatus) =>
              onPaymentStatusChange(paymentStatus),
          },
        }
        if (!Config.isProduction) {
          otherWidgetOptions = {
            ...otherWidgetOptions,
            origin: 'https://sandbox.wert.io',
          } // this option needed only in sandbox
        }
        const nftOptions: WertWidget['options'] = {
          extra: {
            item_info: {
              author: creatorName,
              image_url: (format !== 'video' && media) || '',
              name: title,
              seller: creatorName,
            },
          },
        }

        setWertOptions({
          ...signedData,
          ...otherWidgetOptions,
          ...nftOptions,
        })

        showWertModal(true)
        /* End - Testing Wert */
      } catch (err) {
        return Promise.reject()
      }
      return Promise.resolve()
    },
    [
      amount,
      creatorName,
      currency,
      format,
      isMobileWidthResolution,
      media,
      onPaymentStatusChange,
      price,
      title,
      walletConnector.walletService,
    ],
  )

  const checkoutMultiChain = useCallback(
    async (data: any): Promise<any> => {
      try {
        const transactionMethod = Web3Wallet.getMethodInterface(
          rootStore.contracts.params.BEP20[getContractType()].abi,
          data.initial_tx.method,
        )
        const encodedData = walletConnector.walletService.encodeFunctionCall(transactionMethod, [
          data.initial_tx.data.idOrder,
          data.initial_tx.data.SellerBuyer,
          data.initial_tx.data.tokenToBuy,
          data.initial_tx.data.tokenToSell,
          data.initial_tx.data.fee.feeAddresses,
          [
            new BigNumber(data.initial_tx.data.fee.feeAmounts[0]).toFixed(0).toString(),
            new BigNumber(data.initial_tx.data.fee.feeAmounts[1]).toFixed(0).toString(),
          ],
          data.initial_tx.data.signature,
        ])
        const rubicUrlGenerated = isDarkMode
          ? `https://nft-widgets.rubic.exchange/?amount=${price}&data=${encodedData}&theme=dark`
          : `https://nft-widgets.rubic.exchange/?amount=${price}&data=${encodedData}`

        // const rubicUrlGenerated = `https://nft-widgets.rubic.exchange/?amount=${price}&data=${0x8cba32e4392e6b80d04afa65b0d1ff42a3ecd21ef033955c64b445b1fd6515a9c7c49380000000000000000000000000e7adcf53bb5b8691bf2cb5e4511e766adcdf232200000000000000000000000030a47a58459405da8fe5cdddaace66ba005423f10000000000000000000000003ca540f9d2a882e2cab6dc68a2e9ae67ef1af3ab000000000000000000000000000000000000000000000000000000000000007e0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000de0b6b3a7640000000000000000000000000000000000000000000000000000000000000000018000000000000000000000000000000000000000000000000000000000000001e000000000000000000000000000000000000000000000000000000000000002400000000000000000000000000000000000000000000000000000000000000002000000000000000000000000e7adcf53bb5b8691bf2cb5e4511e766adcdf23220000000000000000000000006ac2ad1ce6438830707e0264315fdceb674b505c0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000016345785d8a000000000000000000000000000000000000000000000000000000b1a2bc2ec500000000000000000000000000000000000000000000000000000000000000000041d97db18656f70430bb25ca745174d7ec6216a83c20a437101e5ea8a8a1600da74ecacc01309149fc15aca0e07b00c97be6492f0f4241476eb218ad8540a2c8e91c00000000000000000000000000000000000000000000000000000000000000}`
        setRubicSrc(rubicUrlGenerated)
        setRubicModalVisible(true)
      } catch (err) {
        return Promise.reject()
      }
      return Promise.resolve()
    },
    [isDarkMode, price, walletConnector.walletService],
  )

  const checkAllowance = useCallback(() => {
    if (currency.toUpperCase() === network.native_symbol.toUpperCase()) {
      setIsAllowed(true)
      return
    }
    walletConnector.walletService
      .checkTokenAllowance(
        formatCoinToken(currency),
        standart === 'ERC721' ? price : price * (maximum || 1),
        getCoinDecimalsByName(currency),
        rootStore.contracts.params.EXCHANGE[getContractType()].address,
      )
      .then((res: boolean) => {
        // TODO: Refactor checkTokenAllowance to web2
        setIsAllowed(localStorage.kephi_nft_providerName !== PROVIDER.SEQUENCE && res)
      })
      .catch((err: any) => {
        console.log(err, 'check')
      })
  }, [currency, network.native_symbol, walletConnector.walletService, standart, price, maximum])

  const approveToken = useCallback(() => {
    setApproving(true)
    return isAllowed
      ? new Promise(resolve => resolve(true))
      : walletConnector.walletService
          .approveToken(
            formatCoinToken(currency),
            standart === 'ERC721' ? price : price * (maximum || 1),
            getCoinDecimalsByName(currency),
            rootStore.contracts.params.EXCHANGE[getContractType()].address,
          )
          .then(() => {
            setIsAllowed(true)
            return true
          })
          .catch((err: any) => {
            console.log(err, 'err approve')
            return false
          })
          .finally(() => setApproving(false))
  }, [isAllowed, walletConnector.walletService, currency, standart, price, maximum])

  const checkoutTransaction = useCallback(
    async (data: any): Promise<any> => {
      if (isMultiChainPurchase) return checkoutMultiChain(data)
      if (isCreditCardPurchase) return checkoutCreditCard(data)

      let transaction = null
      approveToken()
        .then(approved => {
          // PARCHE - TODO: Delegar en el CreateTransaction
          if (!approved) return
          const formattedValue =
            localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE
              ? ethers.utils.parseEther(
                Wallet.weiToEth(
                  data.initial_tx.value,
                  getCoinDecimalsByName(currency),
                ),
              )
              : new BigNumber(data.initial_tx.value).toFixed(0)

          transaction = walletConnector.walletService.createTransaction(
            data.initial_tx.method,
            [
              data.initial_tx.data.idOrder,
              data.initial_tx.data.SellerBuyer,
              data.initial_tx.data.tokenToBuy,
              data.initial_tx.data.tokenToSell,
              data.initial_tx.data.fee.feeAddresses,
              [
                new BigNumber(data.initial_tx.data.fee.feeAmounts[0]).toFixed(0).toString(),
                new BigNumber(data.initial_tx.data.fee.feeAmounts[1]).toFixed(0).toString(),
              ],
              data.initial_tx.data.signature,
            ],
            'BEP20',
            {
              gas: data.initial_tx.gas,
              gasPrice: data.initial_tx.gasPrice,
              nonce: data.initial_tx.nonce,
              to: data.initial_tx.to,
              value: formattedValue,
            },
          )
          .then(() => {
            setIsSuccess(true)
            close()
          })
          .finally(() => setApproving(false))
        })
        .catch((err: any) => {
          console.log(err, 'err approve')
        })

      return transaction
    },

    [
      checkoutCreditCard,
      checkoutMultiChain,
      currency,
      approveToken,
      isCreditCardPurchase,
      isMultiChainPurchase,
      walletConnector.walletService,
      setIsSuccess,
      close,
    ],
  )

  const buyToken = useCallback(() => {
    if (user.id) {
      setIsLoading(true)
      storeApi
        .buyToken(
          tokenId || 0,
          standart === 'ERC721' ? 0 : +amount,
          standart === 'ERC721' ? '' : sellerId,
        )
        .then(({data}) => {
          checkoutTransaction(data)
            .then((res: any) => {
              // IF to avoid execute code for multichain - temporaly
              console.log('res', res)
              console.log('!isMultiChainPurchase', !isMultiChainPurchase)
              console.log('!isCreditCardPurchase', !isCreditCardPurchase)
              if (res && !isMultiChainPurchase && !isCreditCardPurchase) {
                myLocalStorage.set(
                  `${shorterAddress(user.address, 8)}_${TOKEN_STATUS.EXCHANGE_PENDING}_${tokenId}`,
                  true,
                )
                storeApi
                  .trackTransaction(res.transactionHash || res.hash, tokenId, sellerId)
                  .then(() => {
                    setTransactionHash(res.transactionHash || res.hash)
                  })
                storeApi.trackMinerTransaction(
                  tokenId,
                  res.transactionHash || res.hash,
                  res.blockNumber,
                  'Buy',
                )
                setIsSuccess(true)
                close()
              }
            })
            .catch((error: any) => {
              notification.error({
                message: 'Error',
                description: 'Something went wrong',
              })
              console.error('error', error)
            })
            .finally(() => setIsLoading(false))
        })
        .catch((error: any) => {
          notification.error({
            message: 'Error',
            description: 'Something went wrong',
          })
          console.error('error', error)
        })
        .finally(() => setIsLoading(false))
    }
  }, [
    user.id,
    user.address,
    tokenId,
    standart,
    amount,
    sellerId,
    checkoutTransaction,
    isMultiChainPurchase,
    isCreditCardPurchase,
    setIsSuccess,
    close,
    setTransactionHash,
  ])

  useEffect(() => {
    if (user.address) checkAllowance()
  }, [user.address, checkAllowance])

  useEffect(() => {
    if (!user.address) return
    getUserBalance()
  }, [getUserBalance, user.address])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  return (
    <>
      <div className={cn(className, styles.checkout)}>
        <div className={cn('h4', styles.title)}>{t('token.checkout.title')}</div>
        <div className={styles.info}>
          {t('token.checkout.text1')} <strong>{title}</strong> {t('token.checkout.text2')}{' '}
          <strong>{creatorName}</strong>
        </div>
        {!isLoading && (
          <div className={styles.table}>
            {standart === 'ERC1155' && (
              <div className={styles.row}>
                <div className={cn(styles.col, styles.colWithInput)}>
                  <TextInput
                    label=""
                    className={styles.amount}
                    value={amount}
                    onChange={(e: any) => setAmount(e.target.value)}
                    type="number"
                    name="amount"
                    placeholder={t('token.checkout.text8')}
                    required
                    positiveOnly
                    max={maximum}
                  />
                </div>

                <div className={styles.col}>{t('token.checkout.tokensQuantity')}</div>
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.col}>{price}</div>
              <div className={styles.col}>{currency}</div>
            </div>
            {!isCreditCardPurchase && (
              <div className={styles.row}>
                <div className={styles.col}>{t('your_balance')}</div>
                <div className={styles.col}>
                  {balance} {currency}
                </div>
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.col}>{t('service_fee')}</div>
              <div className={styles.col}>{fee}%</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>{t('you_will_pay')}</div>
              <div className={styles.col}>
                {standart === 'ERC1155' ? (price * +amount).toFixed(2) : price} {currency}
              </div>
            </div>
          </div>
        )}
        {!isLoading && !isVerified && (
          <div className={styles.attention}>
            <div className={styles.preview}>
              <Icon name="info-circle" size="32" />
            </div>
            <div className={styles.details}>
              <div className={styles.subtitle}>{t('token.checkout.text3')}</div>
              <div className={styles.text}>{t('token.checkout.text4')}</div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className={styles.line}>
            <div className={styles.icon}>
              <LoaderCircle className={styles.loader} />
            </div>
            <div className={styles.details}>
              <div className={styles.subtitle}>{t('token.checkout.text5')}</div>
              <div className={styles.text}>{t('token.checkout.text6')}</div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div className={styles.btns}>
            <Button
              type="button"
              className={cn('button', styles.button)}
              onClick={buyToken}
              disabled={
                !isMultiChainPurchase &&
                !isCreditCardPurchase &&
                (+balance < price * +amount || (standart === 'ERC1155' && +amount === 0))
              }
              loading={isLoading || isApproving}>
              {t('token.checkout.text7')}
            </Button>
            <Button
              type="button"
              className={cn('button-stroke', styles.button)}
              onClick={() => close()}>
              {t('cancel')}
            </Button>
          </div>
        )}
      </div>

      <Modal
        containerClassName={styles.rubicModalContainer}
        visible={rubicModalVisible}
        onClose={() => setRubicModalVisible(false)}
        closeOnRight>
        {!!rubicSrc && (
          <iframe
            title="Rubic Widget"
            height="550"
            width="100%"
            style={{
              background: '#353945',
              border: 'none',
              borderRadius: '22px',
              display: 'block',
            }}
            src={rubicSrc}
          />
        )}
      </Modal>

      <Modal
        containerClassName={styles.wertContainer}
        outerClassName={styles.wertOuter}
        out
        visible={wertModalVisible}
        onClose={() => showWertModal(false)}>
        {wertModalVisible && !!wertOptions && <WertModule options={wertOptions} />}
      </Modal>
    </>
  )
}

Checkout.defaultProps = {
  className: '',
  maximum: 1,
  isMultiChainPurchase: false,
  isCreditCardPurchase: false,
}

export default Checkout
