import React from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {Link, useHistory} from 'react-router-dom'
import cn from 'classnames'

import {useMst} from '../../store/store'

import styles from './UploadVariants.module.scss'

const Upload: React.FC = () => {
  const {t} = useTranslation()
  const {
    user: {is_minter},
  } = useMst()
  const history = useHistory()

  if (!is_minter) history.push('/')

  const items = [
    {
      url: '/create-item-single',
      buttonText: t('create.single'),
      image: '/images/collectibles/single.png',
      alt: 'single collectible box',
    },
    {
      url: '/create-item-multiple',
      buttonText: t('create.multiple'),
      image: '/images/collectibles/multiple.png',
      alt: 'multiple collectible box',
    },
  ]

  return (
    <div className={styles.page}>
      <section className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.top}>
            <div className={styles.title}>{t('create.title')}</div>
            <div className={styles.info}>{t('create.info')}</div>
          </div>
          <div className={styles.list}>
            {items.map(x => (
              <Link key={nextId()} className={styles.item} to={x.url}>
                <div className={styles.preview}>
                  <img src={x.image} alt={x.alt} />
                </div>
                <div className={styles.cardFooter}>
                  <button className={styles.button} type="button">
                    {x.buttonText}
                  </button>
                </div>
              </Link>
            ))}
          </div>
          <div className={styles.note}>{t('create.text')}</div>
        </div>
      </section>
    </div>
  )
}

export default Upload
