import {useState} from 'react'
import nextId from 'react-id-generator'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

// import { useTranslation } from 'react-i18next';
import Icon from '../Icon'

import styles from './Dropdown.module.scss'

interface IDropdownProps {
  className?: string
  value: string
  setValue: (str: string) => void
  options: Array<any>
  isWithImage?: boolean
  isWritable?: boolean
  name?: string

  customClasses?: {
    head?: string
    selection?: string
    arrow?: string
    body?: string
  }
}

const Dropdown: React.FC<IDropdownProps> = ({
  className,
  value,
  setValue,
  options,
  isWithImage,
  isWritable,
  name,
  customClasses = {},
}) => {
  // const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const handleClick = (str: string) => {
    setValue(str)
    setVisible(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className, {[styles.active]: visible})} id={name}>
        <div
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
          className={cn(styles.head, customClasses.head)}
          onClick={() => setVisible(!visible)}>
          {isWritable ? (
            <input value={value} className={styles.input} />
          ) : (
            <div className={cn(styles.selection, customClasses.selection)}>{value}</div>
          )}
          <div className={cn(styles.arrow, customClasses.arrow)}>
            <Icon name="arrow-bottom" size="13" />
          </div>
        </div>
        {!isWithImage ? (
          <div className={cn(styles.bodyContainer, customClasses.body)}>
            <div className={styles.body}>
              {typeof options[0] === 'string'
                ? options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={cn(styles.option, {
                        [styles.selectioned]: option === value,
                      })}
                      onClick={() => handleClick(option)}
                      key={nextId()}>
                      {option}
                    </div>
                  ))
                : options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={cn(
                        styles.option,
                        {
                          [styles.selectioned]: option.text === value,
                        },
                        option.text === value ? 'text-gradient' : '',
                      )}
                      onClick={() => handleClick(option.text)}
                      key={nextId()}>
                      {option.icon}
                      <span className={styles.text}>{option.text}</span>
                    </div>
                  ))}
            </div>
          </div>
        ) : (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {options.map((x: any) => (
                <div
                  onKeyDown={() => {}}
                  tabIndex={0}
                  role="button"
                  className={cn(
                    styles.option,
                    {
                      [styles.selectioned]: x.symbol === value,
                    },
                    x.symbol === value ? 'text-gradient' : '',
                  )}
                  onClick={() => handleClick(x.symbol)}
                  key={nextId()}>
                  <img alt="" className={styles.image} src={x.image} />
                  <span className={styles.text}>{x.symbol}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
