import {useEffect, useState} from 'react'
import {observer} from 'mobx-react'

import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'

import BucketModal, {ModalAction} from './BucketModal'

import styles from './Buckets.module.scss'

interface IBucketsProps {
  userAddress: string
  bucketsData: {
    buckets: any[]
    id: string
    name: string
    creator: {
      address: string
    }
  }[]
  nftsCreated: any[]
  handleUpdateBuckets: () => void
  page: number
}

const Buckets: React.FC<IBucketsProps> = ({
  userAddress,
  bucketsData,
  handleUpdateBuckets,
  page,
  nftsCreated,
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [modalAction, setModalAction] = useState<ModalAction>(ModalAction.NEW_BUCKET)
  const [editingNft, setEditingNft] = useState<any>(null)
  const [isCreator, setIsCreator] = useState(false)

  const bucketsModalData = bucketsData.map(bucket => {
    return {
      id: bucket.id,
      name: bucket.name,
      buckets: bucket.buckets,
    }
  })

  const handleNew = () => {
    setVisibleModal(true)
    setModalAction(ModalAction.NEW_BUCKET)
  }

  const handleEdit = (bucket: any) => {
    setEditingNft({
      value: bucket.id,
      label: bucket.name,
    })
    setVisibleModal(true)
    setModalAction(ModalAction.EDIT_BUCKET)
  }

  useEffect(() => {
    handleUpdateBuckets()
  }, [visibleModal, handleUpdateBuckets])

  useEffect(() => {
    if (nftsCreated.length === 0) {
      setIsCreator(false)
      return
    }
    setIsCreator(true)
  }, [userAddress, isCreator, nftsCreated])

  return (
    <div className={styles.container}>
      <div className={styles.bucketsHeader}>
        <h1 className={styles.title}>My Buckets</h1>
        <div className={styles.bucketsHeaderSub}>
          {/* true */}
          {isCreator && (
            <>
              <div className={styles.bucketEdit}>
                <button type="button" onClick={handleNew} className={styles.bucketButton}>
                  New
                </button>
                <Icon name="plus-circle" fill="white" />
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.buckets}>
        {bucketsData?.map((bucket: any) => {
          return (
            <div className={styles.bucket} key={bucket.nftId}>
              <div className={styles.bucketHeader}>
                <span>{bucket.name}</span>

                <div className={styles.bucketEdit}>
                  <button
                    type="button"
                    onClick={() => handleEdit(bucket)}
                    className={styles.bucketButton}>
                    Edit
                  </button>
                  <Icon name="edit" fill="white" />
                </div>
              </div>

              <div className={styles.bucketSubHeader}>
                <span>Link</span>
                <span>Updated</span>
              </div>

              <div className={styles.bucketTable}>
                {bucket.buckets.map((link: any) => {
                  return (
                    <div className={styles.bucketContent} key={link.id}>
                      <div className={styles.bucketItem}>
                        <span>
                          <a href={link.link} target="_blank" rel="noreferrer">
                            {link.link}
                          </a>
                        </span>
                        <span> {new Date(link.modified_at).toLocaleDateString()} </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        containerClassName={styles.modalContainer}>
        <BucketModal
          buckets={bucketsModalData}
          action={modalAction}
          setVisibleModal={setVisibleModal}
          handleUpdateBuckets={handleUpdateBuckets}
          shownUserAddress={userAddress}
          page={page}
          nftSelected={editingNft}
        />
      </Modal>
    </div>
  )
}

export default observer(Buckets)
