import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'

import DropdownWithImage from '../../../../../components/DropdownWithImage'
import Switch from '../../../../../components/Switch'
import TextInput from '../../../../../components/TextInput'
import {storeApi} from '../../../../../services/api'
import {useMst} from '../../../../../store/store'
import {Rate} from '../../../../../store/User'
import {getCoinNameByType} from '../../../../../utils/coins'
import {ICreateForm} from '../../../types/Stepper'

import styles from './TypeOfSale.module.scss'

const PUT_ON_SALE = 'putOnSale'
const INSTANT_SALE_PRICE = 'instantSalePrice'
const INSTANT_SALE_PRICE_ETH = 'instantSalePriceEth'
const BID = 'bid'
const CURRENCY = 'currency'

export type TypeOfSaleForm = {
  putOnSale: boolean
  instantSalePrice: boolean
  instantSalePriceEth: string
  bid: string
  currency: string
}

const TypeOfSaleStep: React.FC<ICreateForm> = ({lastStep, nextStep, onSubmit, currentData}) => {
  const {t} = useTranslation()
  const {
    user: {refreshRates, rates},
  } = useMst()
  const mainCurrency = getCoinNameByType('main').toLocaleLowerCase()
  const usdCurrency = getCoinNameByType('usd').toLocaleLowerCase()
  const [serviceFee, setServiceFee] = useState(0)

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: {errors},
  } = useForm<TypeOfSaleForm>({
    defaultValues: {
      putOnSale: currentData.putOnSale,
      instantSalePrice: currentData.instantSalePrice,
      instantSalePriceEth: currentData.instantSalePriceEth,
      bid: currentData.bid,
      currency: 'USDT',
      // currentData.currency ||
      // getCoinNameByType("usd") ||
      // getCoinNameByType("main"),
    },
  })

  const [putOnSale, instantSalePrice, instantSalePriceEth, bid, currency] = watch([
    PUT_ON_SALE,
    INSTANT_SALE_PRICE,
    INSTANT_SALE_PRICE_ETH,
    BID,
    CURRENCY,
  ])

  const fetchFee = useCallback(() => {
    storeApi.getFee(currency === 'kphi' ? 'kphi' : '').then(({data}: any) => {
      setServiceFee(data)
    })
  }, [currency])

  const currentCurrPrice = useMemo(() => {
    if (instantSalePriceEth) {
      return ((parseFloat(instantSalePriceEth) * (100 - serviceFee)) / 100).toString()
    }
    if (bid) {
      return ((parseFloat(bid) * (100 - serviceFee)) / 100).toString()
    }
    return ''
  }, [instantSalePriceEth, serviceFee, bid])

  const currentCurrPriceUSD = useMemo(() => {
    if (rates.toJSON().length) {
      const usdValue = new BigNumber(currentCurrPrice)
        .multipliedBy(
          rates
            .toJSON()
            .find((rate: Rate) => rate.symbol.toLocaleLowerCase() === currency.toLocaleLowerCase())
            ?.rate || '0',
        )
        .toString()
      return usdValue.length > 10 ? `${usdValue.slice(0, 10)}...` : usdValue
    }
    return ''
  }, [rates, currentCurrPrice, currency])

  useEffect(() => {
    refreshRates()
    fetchFee()
  }, [fetchFee, currency, refreshRates])

  const handleApply = (data: TypeOfSaleForm) => {
    onSubmit(data)
    nextStep()
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleApply)}>
      <div className={styles.title}>{t('create_form.typeOfSale.title')}</div>
      <div className={styles.option}>
        <div className={styles.box}>
          <div className={styles.flexBox}>
            <div className={styles.category}>{t('create_form.typeOfSale.putOnSaleQuestion')}</div>
            {/* <div className={styles.tooltipInfo}>
              <p>{t('tooltipInfo.putOnSale')}</p>
            </div> */}
          </div>
        </div>
        <Controller
          control={control}
          name={PUT_ON_SALE}
          render={({field: {name, value, onChange}}) => (
            <Switch
              className={styles.switch}
              customClasses={{
                input: styles.switchInput,
                inner: styles.switchInner,
                box: styles.switchBox,
              }}
              id={name}
              value={value}
              setValue={(putOnSaleValue: boolean) => {
                onChange(putOnSaleValue)
                setValue(INSTANT_SALE_PRICE_ETH, '')
                setValue(BID, '')
                setValue(INSTANT_SALE_PRICE, true)
              }}
            />
          )}
        />
      </div>
      {putOnSale && (
        <>
          <div className={styles.option}>
            <div className={styles.box}>
              <div className={styles.flexBox}>
                <div className={styles.category}>{t('create_form.typeOfSale.modalityLabel')}</div>
                {/* <div className={styles.tooltipInfo}>
                  <p>{t('tooltipInfo.instSalePrice')}</p>
                </div> */}
              </div>
              <div className={styles.text}>{t('create_form.typeOfSale.modalityDescription')}</div>
            </div>
          </div>
          <div className={styles.rowModalityButtons}>
            <button
              type="button"
              onClick={() => {
                setValue(INSTANT_SALE_PRICE, true)
                setValue(INSTANT_SALE_PRICE_ETH, '')
                setValue(BID, '')
                setValue(CURRENCY, usdCurrency || mainCurrency)
              }}
              className={classNames('button-outlined', styles.modalityButton, {
                [styles.selected]: instantSalePrice,
              })}>
              {t('create_form.typeOfSale.fixedPrice')}
            </button>
            <button
              type="button"
              onClick={() => {
                setValue(INSTANT_SALE_PRICE, false)
                setValue(INSTANT_SALE_PRICE_ETH, '')
                setValue(BID, '')
                setValue(CURRENCY, usdCurrency || mainCurrency)
              }}
              className={classNames('button-outlined', styles.modalityButton, {
                [styles.selected]: !instantSalePrice,
              })}>
              {t('create_form.typeOfSale.auction')}
            </button>
          </div>
          <Controller
            control={control}
            name={instantSalePrice ? INSTANT_SALE_PRICE_ETH : BID}
            rules={{
              required: {
                value: !!putOnSale,
                message: instantSalePrice ? t('validate.price1') : t('validate.bid'),
              },
              ...(instantSalePrice && {
                min: {
                  value: 0.001,
                  message: t('validate.price2'),
                },
              }),
            }}
            render={({field: {name, value, onChange, onBlur}}) => (
              <div style={{position: 'relative'}} className={styles.fieldset}>
                <TextInput
                  name={name}
                  label={
                    instantSalePrice
                      ? t('create_form.typeOfSale.priceLabel')
                      : t('create_form.minimal_bid')
                  }
                  value={value}
                  positiveOnly
                  placeholder={
                    instantSalePrice
                      ? t('create_form.enter_price_place')
                      : t('create_form.minimal_bid_place')
                  }
                  type="number"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                />
                {!!rates.toJSON().length && (
                  <Controller
                    control={control}
                    name={CURRENCY}
                    render={() => (
                      <DropdownWithImage
                        className={styles.cryptocurrenciesDropdown}
                        value="USDT"
                        options={['USDT']}
                        setValue={() => setValue(CURRENCY, 'USDT')}
                        customClasses={{
                          head: styles.cryptocurrenciesDropdownHead,
                          selection: styles.cryptocurrenciesDropdownSelection,
                          arrow: styles.cryptocurrenciesDropdownArrow,
                        }}
                      />
                    )}
                  />
                )}
              </div>
            )}
          />
          <div className={styles.fee}>
            <span className={styles.feeItem}>
              {t('service_fee')}{' '}
              <span className={classNames(styles.fieldSuggestion, styles.serviceFee)}>
                {serviceFee}%
              </span>
            </span>
            {(instantSalePriceEth || bid) && (
              <span className={styles.feeItem}>
                {t('token.you_recieve')}{' '}
                <span className={classNames(styles.fieldSuggestion, styles.recieve)}>
                  {currentCurrPrice.length > 10
                    ? `${currentCurrPrice.slice(0, 10)}...`
                    : currentCurrPrice}{' '}
                  {currency.toUpperCase()}
                </span>{' '}
                {!!rates.toJSON().length && (
                  <div className={styles.recieveD}>U$S {currentCurrPriceUSD}</div>
                )}
              </span>
            )}
          </div>
        </>
      )}
      <div className={styles.buttonsRow}>
        {lastStep && (
          <button
            className={classNames('button-outlined-gradient', styles.backButton)}
            type="button"
            onClick={() => lastStep()}>
            {t('back')}
          </button>
        )}
        <button className={classNames('button-gradient', styles.nextbutton)} type="submit">
          {t('next')}
        </button>
      </div>
    </form>
  )
}

export default TypeOfSaleStep
