import {useTranslation} from 'react-i18next'
import {NavHashLink} from 'react-router-hash-link'
import cn from 'classnames'

import CardSkeleton from '../../../components/Card/Skeleton/CardSkeleton'
import Collection from '../../../components/Collection/Collection'
import {PROFILE_ITEMS_PER_PAGE} from '../../../constants/constants'

import styles from './UserCollections.module.scss'

interface UserCollectionsProps {
  className?: string
  collections: any
  isLoading: boolean
  isMe: boolean
}

const UserCollections: React.FC<UserCollectionsProps> = ({
  className,
  collections,
  isLoading,
  isMe,
}) => {
  const {t} = useTranslation()

  const renderCollections = () => {
    const emptytext = isMe ? (
      <div className={styles.text}>
        {t('user.noCollectionsMessage')}{' '}
        <NavHashLink smooth to="/#explore" className={styles.textActiveLink}>
          {t('user.here')}
        </NavHashLink>{' '}
        {t('user.to_create')}
      </div>
    ) : (
      <div className={styles.text}>{t('user.noCollectionsMessage')}</div>
    )

    if (!collections?.length) return emptytext
    return collections.map((collection: any) => (
      <div className={styles.card}>
        <Collection key={collection.id} collection={collection} />
      </div>
    ))
  }

  const cardSkeletons = Array.from({length: PROFILE_ITEMS_PER_PAGE}, (_value, index) => index)

  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.list}>
        {isLoading
          ? cardSkeletons.map(cardSkeleton => (
              <CardSkeleton className={styles.card} key={cardSkeleton} />
            ))
          : renderCollections()}
      </div>
    </div>
  )
}

export default UserCollections
