import React, {useState} from 'react'
import {HiArrowUturnLeft} from 'react-icons/hi2'

import styles from './TrackCard.module.scss'

type TrackCardProps = {
  track: {
    avatar: string
    name: string
    description: string
  }
  trackDetails: {
    artist: string
    songTitle: string
  }
}

const TrackCard: React.FC<TrackCardProps> = ({track, trackDetails}: TrackCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleCardClick = () => {
    setIsFlipped(!isFlipped)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setIsFlipped(!isFlipped)
    }
  }

  return (
    <div className={styles.card__container}>
      <div
        className={`${styles.card} ${isFlipped ? styles.flipped : ''}`}
        onClick={handleCardClick}
        onKeyPress={handleKeyPress}
        tabIndex={0}
        role="button">
        <div className={styles.card_inner}>
          <div className={styles.card__content}>
            <div className={`${styles.card__imageWrapper} ${isFlipped ? styles.reverseImage : ''}`}>
              <img src={track?.avatar} alt="Collection" className={styles.card__image} />
              {!isFlipped && (
                <div>
                  <span>
                    <HiArrowUturnLeft size={42} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.card__back} ${isFlipped ? styles.showBack : ''}`}>
            <div className={`${styles.card__overlay} ${styles.card__textWrapper}`}>
              <h1 className={styles.card__text}>{trackDetails.songTitle}</h1>
              <h2 className={styles.card__text}>{trackDetails.artist}</h2>
              <p>{track?.description}</p>
              <a className={styles.card__text} href="advanced-search">
                Go to marketplace
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrackCard
