import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {Link} from 'react-router-dom'
import BigNumber from 'bignumber.js/bignumber'
import classNames from 'classnames'
import {observer} from 'mobx-react'

import {userApi} from '../../services/api'
import Icon from '../Icon'

import styles from './Card.module.scss'

interface ICardProps {
  className?: string
  item: any
  isOwned?: boolean
}

interface ICardHoverMenuProps {
  itemId: string
  chainText: string
  hasLike?: boolean
}

const CardHoverMenu: React.FC<ICardHoverMenuProps> = ({chainText, hasLike, itemId}) => {
  const [visible, setVisible] = useState(Boolean(hasLike))

  const fetchLike = useCallback(() => {
    userApi.like({id: itemId ? +itemId : 0})
  }, [itemId])
  useEffect(() => setVisible(hasLike || false), [hasLike])

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault()
      setVisible(prev => !prev)
      fetchLike()
    },
    [fetchLike],
  )

  return (
    <div className={styles.control}>
      <div className={classNames('status-green', styles.chain)}>{chainText}</div>
      {!!localStorage.beatblox_nft_chainName && (
        <button
          type="button"
          aria-label="Like"
          className={classNames(styles.favorite, {
            [styles.active]: visible,
            [styles.liked]: visible,
          })}
          onClick={handleClick}>
          <Icon name="heart" size="20" />
        </button>
      )}
    </div>
  )
}

const Card: React.FC<ICardProps> = observer(({className, item, isOwned}) => {
  const {t} = useTranslation()

  return (
    <div className={classNames(styles.card, className)}>
      <Link to={`/item/${item.id}`} className={styles.preview}>
        <img src={item.media_low_resolution} alt="Card" />
        <CardHoverMenu chainText={item.network.name} hasLike={item.is_liked} itemId={item.id} />
      </Link>
      <Link className={styles.link} to={`/item/${item.id}`}>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.nameContainer}>
              <div
                className={classNames(styles.nameLabel, {
                  [styles.scrollToRight]: item.name.length > 23,
                })}>
                {item.name}
              </div>
            </div>
            {(item.price || (!item.bids.length && item.minimal_bid)) && (
              <div className={styles.price}>
                <div className={styles.inner}>
                  <span className={styles.innerText}>
                    {item.price || item.minimal_bid} {item.currency.symbol}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={styles.line}>
            <div className={styles.users}>
              {item.owners &&
                (item.standart === 'ERC721' ? (
                  <div className={styles.avatar} key={nextId()}>
                    <img src={item.owners.avatar} alt="Avatar" />
                  </div>
                ) : (
                  item.owners.map((x: any, index: number) => {
                    return index < 5 ? (
                      <div className={styles.avatar} key={JSON.stringify(x)}>
                        <img src={x.avatar} alt="Avatar" />
                      </div>
                    ) : (
                      <></>
                    )
                  })
                ))}
            </div>
            {!isOwned ? (
              <div className={styles.counter}>
                {item.available === item.total_supply
                  ? item.total_supply
                  : `${item.available}/${item.total_supply}`}{' '}
                {(item.available || 0) <= 1
                  ? t('card.availableSingular')
                  : t('card.availablePlural')}
              </div>
            ) : (
              <div className={styles.counter}>
                {item.owner_quantity}{' '}
                {(item.owner_quantity || 0) <= 1
                  ? t('card.availableSingular')
                  : t('card.availablePlural')}
              </div>
            )}
          </div>
        </div>
        {item.highest_bid || item.like_count ? (
          <div className={styles.foot}>
            {item.highest_bid ? (
              <div className={styles.status}>
                <Icon name="candlesticks-up" size="20" />
                Highest bid <span>{new BigNumber(item.highest_bid.amount).toFixed(3)}</span>
              </div>
            ) : (
              ''
            )}
            {!!item.like_count && (
              <div className={styles.likesCounter}>
                {item.like_count} <Icon name="like" size="16" />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </Link>
    </div>
  )
})

export default Card
