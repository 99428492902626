import Select from 'react-select'

const CUSTOM_STYLES = {
  controlStyles: {
    color: 'white',
    border: '1px solid white',
    borderRadius: '15px',
    backgroundColor: 'black',
    width: '17rem',
  },
  optionStyles: {
    color: 'white',
    backgroundColor: 'black',
  },
  placeholderStyles: {
    color: 'white',
    cursor: 'none',
    paddingLeft: '0.5rem',
  },
  singleValueStyles: {
    color: 'white',
    paddingLeft: '0.5rem',
  },
  menuStyles: {
    backgroundColor: 'black',
    borderRadius: '15px',
    color: 'white',
  },
  menuListStyles: {
    backgroundColor: 'black',
    borderRadius: '15px',
    color: 'white',
  },
}

type SelectItemsProps = {
  placeholder: string
  options: {value: string; label: string}[]
  onChange: (selectedOption: any) => void
  value?: {value: string; label: string}
  isDisabled?: boolean
}

const SelectItems: React.FC<SelectItemsProps> = ({
  placeholder,
  options,
  onChange,
  value,
  isDisabled = false,
}) => {
  return (
    <Select
      id="select-items"
      options={options}
      placeholder={options.length > 0 ? placeholder : options[0]}
      isDisabled={isDisabled}
      value={value}
      styles={{
        control: controlStyles => ({
          ...controlStyles,
          ...CUSTOM_STYLES.controlStyles,
        }),
        option: optionStyles => ({
          ...optionStyles,
          ...CUSTOM_STYLES.optionStyles,
        }),
        placeholder: placeholderStyles => ({
          ...placeholderStyles,
          ...CUSTOM_STYLES.placeholderStyles,
        }),
        singleValue: singleValueStyles => ({
          ...singleValueStyles,
          ...CUSTOM_STYLES.singleValueStyles,
        }),
        menu: menuStyles => ({
          ...menuStyles,
          ...CUSTOM_STYLES.menuStyles,
        }),
        menuList: menuListStyles => ({
          ...menuListStyles,
          ...CUSTOM_STYLES.menuListStyles,
        }),
      }}
      onChange={onChange}
    />
  )
}

export default SelectItems
