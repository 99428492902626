import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import cn from 'classnames'

import Button from '../Button'
import Checkbox from '../Checkbox'

import styles from './TermsOfService.module.scss'

interface ITermsOfService {
  handleConnect: () => void
  handleCancel: () => void
}

const TermsOfService: React.FC<ITermsOfService> = ({handleConnect, handleCancel}) => {
  const {t} = useTranslation()
  const [age, setAge] = useState(true)
  const [conditions, setConditions] = useState(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <h4 className={cn('h4', styles.title)}>{t('terms.title')}</h4>
        <p className={styles.text}>
          <span>{t('terms.text1')} </span>
          <a
            className={styles.textActiveLink}
            href="https://ipfs.io/ipfs/QmXBVWapZj24SsBwn7QfDPVfM5ouk7ocsCVCrp5vATGKkN">
            {t('terms.text2')}
          </a>
          <span>. {t('terms.text3')}</span>
        </p>
        <div className={styles.variants}>
          <Checkbox
            className={styles.checkbox}
            value={age}
            onChange={() => setAge(!age)}
            content={t('terms.check1')}
          />
          <Checkbox
            className={styles.checkbox}
            value={conditions}
            onChange={() => setConditions(!conditions)}
            content={t('terms.check2')}
          />
        </div>
        <div className={styles.btns}>
          <Button
            type="button"
            className={cn('button-stroke', styles.cancelButton)}
            onClick={() => handleCancel()}>
            {t('cancel')}
          </Button>
          <Button
            type="button"
            className={cn('button', styles.confirmButton)}
            onClick={handleConnect}
            disabled={!age || !conditions}>
            {t('terms.btn')}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default TermsOfService
