import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import cn from 'classnames'
import {observer} from 'mobx-react'

import {CreateForm} from '../../forms'
import {useWalletConnectorContext} from '../../services/walletConnect'
import {useMst} from '../../store/store'

import styles from './UploadDetails.module.scss'

interface IUploadDetails {
  isSingle: boolean
}

const UploadDetails: React.FC<IUploadDetails> = observer(({isSingle}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const walletConnector = useWalletConnectorContext()
  const {
    user: {is_minter},
  } = useMst()

  if (!is_minter) history.push('/')
  return (
    <>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.wrapper}>
            <div className={styles.head}>
              <div className={cn('h2', styles.title)}>
                {isSingle ? t('create_form.title_single') : t('create_form.title_multiple')}
              </div>
              <button
                type="button"
                onClick={
                  isSingle
                    ? () => history.push('/upload-details-multiple')
                    : () => history.push('/upload-details-single')
                }
                className={cn('button-small', styles.button)}>
                {t('create_form.switch')} {isSingle ? t('multiple') : t('single')}
              </button>
            </div>
            <CreateForm isSingle={isSingle} walletConnector={walletConnector} />
          </div>
        </div>
      </div>
    </>
  )
})

export default UploadDetails
