import {ContractAddress} from '../types/contract'

export const contractAddress: ContractAddress = {
  EXCHANGE: {
    mainnet: {
      Binance: '0xEca42E21C0D44a7Df04F1f0177C321a123eA9B14',
      KardiaChain: '0xEca42E21C0D44a7Df04F1f0177C321a123eA9B14',
      // Polygon: '0x07BC196896Ec92D037531B9Fa738b81d6B126949',
      Polygon: '0xeFc60d4bF3F7E9d6a08B81Ed13dDbB00FdC0d790',
      Ethereum: '0x65D2829872E060BD983b6069872Acd5622EfF365',
    },
    testnet: {
      Binance: '0xDa5Ff1B1A66C45f4B229A410642459237c6BA582',
      KardiaChain: '',
      // Polygon: '0x24a354CDc1306a2D2cd99DDb686E13aC701814a5',
      Polygon: '0xd25F5E552A0C3b839A328D6aa2d169AA34cC75a1',
      Ethereum: '0x24a354CDc1306a2D2cd99DDb686E13aC701814a5',
    },
  },
  BUSD: {
    mainnet: {
      Binance: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
  },
  WBNB: {
    mainnet: {
      Binance: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
  },
  WMATIC: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      Ethereum: '',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
      Ethereum: '',
    },
  },
  USDT: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      Ethereum: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0x2d7882beDcbfDDce29Ba99965dd3cdF7fcB10A1e',
      Ethereum: '0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C',
    },
  },
  USDC: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      Ethereum: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1',
      Ethereum: '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1',
    },
  },
  KPHI: {
    mainnet: {
      Binance: '0xFa4A5C4ce029FD6872400545df44675219C2e037',
      KardiaChain: '0x6CD689DefCA80f9F2CBED9D0C6f3B2Cf4abc4598',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '0xF47b96c3917EEa571CA1ECDA4b5D9F99EF6d9E85',
      KardiaChain: '0xa26A0c2c9E81332C6654922b3800e2487a49D40F',
      Polygon: '',
      Ethereum: '',
    },
  },
  KUSDT: {
    mainnet: {
      Binance: '',
      KardiaChain: '0x92364Ec610eFa050D296f1EEB131f2139FB8810e',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '',
      KardiaChain: '0x5070383762806D838EbA8d7a877075b77d59FE74',
      Polygon: '',
      Ethereum: '',
    },
  },
  WKAI: {
    mainnet: {
      Binance: '',
      KardiaChain: '0xAF984E23EAA3E7967F3C5E007fbe397D8566D23d',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '',
      KardiaChain: '0x02655eEF130e5F37F8b648BD9c8D10aA2e6bf698',
      Polygon: '',
      Ethereum: '',
    },
  },
  WETH: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
  },
  NFT: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
  },
  BEP20: {
    mainnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
    testnet: {
      Binance: '',
      KardiaChain: '',
      Polygon: '',
      Ethereum: '',
    },
  },
}
