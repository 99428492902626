import {useCallback, useEffect, useState} from 'react'

import {storeApi} from '../../../services/api'
import {useMst} from '../../../store/store'

import styles from './ScoreTracker.module.scss'

type ScoreTrackerProps = {
  scoreEarned: number
  internalIdCurrentCollection: string
}

const ScoreTracker: React.FC<ScoreTrackerProps> = ({
  scoreEarned,
  internalIdCurrentCollection,
}: ScoreTrackerProps) => {
  const [score, setScore] = useState(0)
  const {user} = useMst()

  const fetchScore = useCallback(async () => {
    try {
      const {total_score} = await storeApi.getScore()
      setScore(total_score)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const postScore = useCallback(async () => {
    try {
      const {total_score} = await storeApi.getScore()
      if (total_score === score) return
      const scoreDifference = score - total_score
      await storeApi.setScore(scoreDifference)
    } catch (error) {
      console.log(error)
    }
  }, [score])

  useEffect(() => {
    fetchScore()
  }, [fetchScore])

  useEffect(() => {
    if (scoreEarned) {
      setScore(prevScore => prevScore + 5)
      postScore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreEarned])

  return (
    <div className={styles.counter__container}>
      <a href={`/item/${internalIdCurrentCollection}`} className={styles.buyTrack__btn}>
        <span className={styles.buyTrack__text}>Buy Track</span>
      </a>
      <span className={styles.score}>
        Score {user.id ? `: ${score}` : ''}
        <span className={styles.tooltip__text}>
          Score is the time you spend during the session. Remember to log in to use your score for
          bonuses based on time later on.
        </span>
      </span>
    </div>
  )
}

export default ScoreTracker
