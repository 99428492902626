import React, {Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'

import {ReactComponent as Binance} from '../../../assets/img/icons/binance-icon-logo.svg'
import {ReactComponent as Kardiachain} from '../../../assets/img/icons/kardiachain.svg'
import {ReactComponent as Polygon} from '../../../assets/img/icons/polygon-matic-logo.svg'
import {BlockchainType} from '../../../types/blockchain'

import styles from './ChooseBlockchain.module.scss'

const blockchains: Array<{
  title: string
  icon: ReactElement
  chain: BlockchainType
  inactive?: boolean
}> = [
  {
    title: 'Binance Smart Chain',
    icon: <Binance />,
    chain: 'Binance',
  },
  {
    title: 'Polygon',
    icon: <Polygon />,
    chain: 'Polygon',
    inactive: process.env.REACT_APP_POLYGON_ACTIVE === 'false',
  },
  {
    title: 'Kardiachain',
    icon: <Kardiachain />,
    chain: 'KardiaChain',
  },
]

const MAX_WIDTH_MOBILE = 1179

interface IChooseBlockchainProps {
  setActiveChain: Dispatch<SetStateAction<BlockchainType | undefined>>
}

const ChooseBlockchain: React.FC<IChooseBlockchainProps> = ({setActiveChain}) => {
  const {t} = useTranslation()
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  return (
    <>
      <div className={styles.head}>
        <div className={styles.back}>
          <h2 className={cn('h2', styles.stage)}>{t('chooseBlockchain.title')}</h2>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.description}>
          <span>{t('chooseBlockchain.description')}</span>
        </p>
        <ul className={styles.menu}>
          {blockchains.map(blockchain => (
            <li className={cn(styles.linkContainer, styles.animate, styles.fade)} key={nextId()}>
              <button
                className={cn(styles.link, {
                  [styles.disable]: !!blockchain.inactive,
                })}
                type="button"
                onClick={() => setActiveChain(blockchain.chain)}
                disabled={!!blockchain.inactive}>
                <div className={styles.icon}>{blockchain.icon}</div>
                <div className={styles.menuItemText}>
                  <div>{blockchain.title}</div>
                  {!!blockchain.inactive && <div className={styles.comingSoon}>(ComingSoon)</div>}
                </div>
              </button>
            </li>
          ))}
        </ul>
        <p className={styles.disclaimer}>{t('connect.text2')}</p>
      </div>
    </>
  )
}

export default ChooseBlockchain
