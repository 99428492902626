export const filterIcons = [
  {id: 0, label: 'ALL ITEMS', iconUrl: '/images/icons/filters/all-items.svg'},
  {id: 1, label: 'IMAGE', iconUrl: '/images/icons/filters/paint.svg'},
  // {id: 2, label: 'PHOTOGRAPHY', iconUrl: '/images/icons/filters/photo.svg'},
  {id: 3, label: 'AUDIO', iconUrl: '/images/icons/filters/music.svg'},
  // {id: 4, label: 'Plastic', iconUrl: '/images/icons/filters/plastic.svg'},
  {id: 5, label: 'VIDEO', iconUrl: '/images/icons/filters/video.svg'},
  // {id: 6, label: 'POETRY', iconUrl: '/images/icons/filters/literature.svg'},
]

// 0 - {label: 'All items'}
// 1 -{label: 'Video'} +
// 2 - {label: 'Audio'} +
// 3 - {label: 'Poetry'} +
// 4 - {label: 'Photography'}
// 5 - {label: 'Animation'}
// 6 - {label: 'Gamifi'}
// 7 - {label: 'Image'} +
