/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import { storeApi } from "../../services/api";
import ScoreTracker from "./ScoreTracker";
import TrackCard from "./TrackCard";
import styles from "./Player.module.scss";
import PlayerControls from "./PlayerControls";
import { splitSongAndArtistName } from "src/utils/format";

const Player: React.FC = () => {
  const [collections, setCollections] = useState<any>([]);
  const [currentCollection, setCurrentCollection] = useState<any>();
  const [internalIdCurrentCollection, setInternalIdCurrentCollection] =
    useState<string>("");

  const [currentSong, setCurrentSong] = useState<string>();
  const [currentSongDetails, setCurrentSongDetails] = useState<any>({});
  const [nextSong, setNextSong] = useState<any>();
  const [scoreEarned, setScoredEarned] = useState(0);
  // TODO: I've move this state to this component to send it also to ScoreTracker and not post to the server if the player is not playing.
  const [isPlaying, setIsPlaying] = useState(true);

  const fetchCollections = useCallback(async () => {
    try {
      const collectionsData = await storeApi.getAllCollections(1, true);
      const { collections } = collectionsData.data;
      setCollections(collections);
      setCurrentCollection(collections[0]);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooksdd/exhaustive-deps
  }, []);

  const fetchCollectionById = useCallback(async (collectionId: string) => {
    try {
      const collectionData = await storeApi.getCollectionById(collectionId, 1);
      const { animation, id } = collectionData.data.tokens[0];
      setCurrentSong(animation);
      setInternalIdCurrentCollection(id.toString());
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updateCurrentCollection = useCallback(() => {
    const nextCollectionIndex = collections.findIndex(
      (collection: any) => collection.id === currentCollection.id
    );
    if (nextCollectionIndex + 1 < collections.length) {
      setCurrentCollection(collections[nextCollectionIndex + 1]);
    } else {
      setCurrentCollection(collections[0]);
    }
  }, [collections, currentCollection]);

  useEffect(() => {
    fetchCollections();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentCollection) {
      fetchCollectionById(currentCollection.id);
    }
    // eslint-disable-next-line
  }, [currentCollection]);

  useEffect(() => {
    if (currentCollection) {
      const nextCollectionIndex = collections.findIndex(
        (collection: any) => collection.id === currentCollection.id
      );
      if (nextCollectionIndex + 1 < collections.length) {
        setNextSong(collections[nextCollectionIndex + 1]);
      } else {
        setNextSong(collections[0]);
      }
    }
  }, [currentCollection, collections]);

  useEffect(() => {
    if (currentCollection) {
      const { artist, songTitle } = splitSongAndArtistName(
        currentCollection.name
      );
      setCurrentSongDetails({ artist, songTitle });
    }
  }, [currentCollection]);

  return (
    <main className={styles.main__container}>
      <div className={styles.player__container}>
        <img
          className={styles.player__asset__background}
          src={currentCollection?.avatar}
          alt={currentCollection?.name}
        />
        <div className={styles.background__container}>
          <ScoreTracker
            scoreEarned={scoreEarned}
            internalIdCurrentCollection={internalIdCurrentCollection}
          />
          <TrackCard
            track={currentCollection}
            trackDetails={currentSongDetails}
          />
          <PlayerControls
            nextSong={nextSong}
            currentSong={currentSong}
            currentSongDetails={currentSongDetails}
            setScoreEarned={setScoredEarned}
            updateCurrentCollection={updateCurrentCollection}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        </div>
      </div>
    </main>
  );
};

export default Player;
