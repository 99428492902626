import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {Link} from 'react-router-dom'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'
import moment from 'moment'

import {activityApi} from '../../services/api'
import {useMst} from '../../store/store'
import Dropdown from '../Dropdown'
import Icon from '../Icon'
import Image from '../Image'
import LogIn, {LoginModal} from '../LogIn/LogIn'
import Searchbar from '../Searchbar/Searchbar'

import User from './User'

import styles from './Header.module.scss'

const Headers: React.FC<any> = observer(({history}) => {
  const [visibleNav, setVisibleNav] = useState(false)
  const {user} = useMst()
  const {t, i18n} = useTranslation()
  const [hasNotifications, setHasNotifications] = useState<boolean>(false)
  const [modalLogInVisible, showModalLogIn] = useState<boolean>(false)

  if (localStorage.getItem('darkMode') !== 'true') localStorage.setItem('darkMode', 'true')
  document.body.className = 'dark-mode'

  const languages = ['EN', 'ES']
  const [language, setLanguage] = useState(
    localStorage.kephi_lang && localStorage.kephi_lang === 'es' ? languages[1] : languages[0],
  )

  const navs = [
    {
      url: '/player',
      title: t('header.menu.player'),
      alwaysActive: true,
    },
    {
      id: 2,
      title: t('header.menu.my_items'),
      alwaysActive: false,
      onClick: () => {
        if (!user.address) showModalLogIn(true)
        else history.push(`/profile/${user.id}/items`)
      },
    },
  ]

  const handleChangeLang = (lang: any) => {
    setLanguage(lang)
    if (lang === 'EN') {
      i18n.changeLanguage('en')
      localStorage.kephi_lang = 'en'
      moment.locale('en')
    } else {
      i18n.changeLanguage('es')
      localStorage.kephi_lang = 'es'
      moment.locale('es')
    }
  }

  const fetchNotification = useCallback(() => {
    activityApi.getNotification().then(({data}: any) => {
      setHasNotifications(data.length)
    })
  }, [])

  useEffect(() => {
    if (user.address) fetchNotification()
    const time = setInterval(() => {
      if (user.address) {
        fetchNotification()
      }
    }, 60000)
    return () => clearInterval(time)
  }, [user.address, fetchNotification])

  useEffect(() => {
    if (localStorage.kephi_lang) {
      i18n.changeLanguage(localStorage.kephi_lang)
      moment.locale(localStorage.kephi_lang)
      setLanguage(localStorage.kephi_lang === 'es' ? languages[1] : languages[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, localStorage.kephi_lang])

  useEffect(() => {
    i18n.changeLanguage('en')
    moment.locale('en')
    setLanguage(languages[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <header className={styles.header}>
        <div className={cn('container', styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/logos/black-beatblox-logo.png"
              srcSetDark="/images/logos/white-beatblox-logo.png"
              alt="Logo with description"
            />
            <Image
              className={styles.pic}
              src="/images/logos/black-initial-beatblox-logo.png"
              srcSetDark="/images/logos/white-initial-beatblox-logo.png"
              alt="Logo"
            />
          </Link>
          <div className={cn(styles.wrapper, {[styles.active]: visibleNav})}>
            <nav className={styles.nav}>
              {navs.map(nav => {
                return (
                  <Link
                    className={cn(styles.link, {
                      [styles.disabled]: !nav.alwaysActive && !user.address,
                    })}
                    to={nav.url || ''}
                    key={nextId()}
                    onClick={() => visibleNav && setVisibleNav(!visibleNav)}>
                    {nav.title.toUpperCase()}
                  </Link>
                )
              })}
            </nav>
            {!!user.address && (
              <>
                <Link className={cn('button-small', styles.button)} to="/upload-variants">
                  {t('header.upload')}
                </Link>
              </>
            )}
          </div>
          <Searchbar />
          <div className={cn(styles.col, styles.colMargin)}>
            <div className={styles.dropdownWrapper}>
              <Dropdown
                className={styles.dropdown}
                setValue={(selectedStr: any) => handleChangeLang(selectedStr)}
                value={language}
                options={languages}
                customClasses={{
                  head: styles.languageDropdownHead,
                  arrow: styles.languageDropdownArrow,
                }}
              />
            </div>
          </div>
          {!!user.address && (
            <Link to="/activity" className={cn(styles.head, {[styles.active]: hasNotifications})}>
              <Icon name="notification" size="24" />
            </Link>
          )}
          {!user.address ? (
            <div className={cn('button-small', styles.login__button)}>
              <img src="/images/login.svg" alt="Login" />
              <LogIn className={styles.login__button} />
            </div>
          ) : (
            <User className={styles.user} />
          )}
          {!!user.address && !!user.is_minter && (
            <Link className={cn('button-small', styles.button)} to="/upload-variants">
              {t('header.create')}
            </Link>
          )}
          <button
            type="button"
            aria-label="Toogle visibility"
            tabIndex={0}
            onKeyDown={() => {}}
            className={cn(styles.burger, {[styles.active]: visibleNav})}
            onClick={() => setVisibleNav(!visibleNav)}
          />
        </div>
      </header>

      <LoginModal modalVisible={modalLogInVisible} showModal={showModalLogIn} />
    </>
  )
})

export default Headers
