import React, {Suspense, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {observer} from 'mobx-react'

import InitialLoader from '../../components/InitialLoader/InitialLoader'
import {LOCAL_STORAGE, REFERRAL_QUERY_PARAM} from '../../constants/constants'
import myLocalStorage from '../../utils/myLocalStorage'

import styles from './Home.module.scss'

const PresentationBanner = React.lazy(() => import('./PresentationBanner/PresentationBanner'))
const Collections = React.lazy(() => import('./Collections'))
const Discover = React.lazy(() => import('./Discover'))

const Home: React.FC = observer(() => {
  const {search} = useLocation()

  useEffect(() => {
    const referralCode = new URLSearchParams(search).get(REFERRAL_QUERY_PARAM)
    if (referralCode) myLocalStorage.set(LOCAL_STORAGE.REFERRAL_CODE, referralCode)
  }, [search])

  return (
    <Suspense
      fallback={
        <div className={styles.loaderContainer}>
          <InitialLoader className={styles.loader} />
        </div>
      }>
      <PresentationBanner />
      <Discover />
      <Collections />
    </Suspense>
  )
})

export default Home
