import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'

import {ReactComponent as Metamask} from '../../../assets/img/icons/metamask.svg'
import {ReactComponent as Polygon} from '../../../assets/img/icons/polygon-matic-logo.svg'
import {ReactComponent as WalletConnect} from '../../../assets/img/icons/wallet-connect.svg'
import Modal from '../../../components/Modal'
import TermsOfService from '../../../components/TermsOfService'
import {BLOCKCHAIN} from '../../../constants/constants'
import {useWalletConnectorContext} from '../../../services/walletConnect'
import {BlockchainType} from '../../../types/blockchain'
import {ProviderType} from '../../../types/provider'

import styles from './ChooseWallet.module.scss'

interface IChainElement {
  name: BlockchainType
  icon: ReactElement
}
interface IProviderElement {
  title: string
  icon: ReactElement
  chains: IChainElement[]
  provider: ProviderType
  disabledOnMobile: boolean
  descriptionTranslation?: string
}

const polygonChain: IChainElement = {name: 'Polygon', icon: <Polygon />}
const chains: Array<IChainElement> = [polygonChain]

const providers: Array<IProviderElement> = [
  {
    title: 'Metamask',
    icon: <Metamask />,
    chains: [polygonChain],
    provider: 'MetaMask',
    disabledOnMobile: true,
  },
  {
    title: 'Wallet Link',
    icon: <WalletConnect />,
    chains: [polygonChain],
    provider: 'WalletLink',
    disabledOnMobile: false,
  },
  {
    title: 'Wallet Connect',
    icon: <WalletConnect />,
    chains: [polygonChain],
    provider: 'WalletConnect',
    disabledOnMobile: false,
    descriptionTranslation: 'connect.walletConnectDescription',
  },
]

const MAX_WIDTH_MOBILE = 1179
interface IChooseWalletProps {
  activeChain: BlockchainType
}

const ChooseWallet: React.FC<IChooseWalletProps> = ({activeChain}) => {
  const {t} = useTranslation()
  const {connect} = useWalletConnectorContext()
  const [isVisibleTermsOfService, setIsVisibleTermsOfService] = useState(false)
  const [chainSelected, setChainSelected] = useState<BlockchainType>(activeChain)
  const [filteredProviders, setFilteredProviders] = useState<IProviderElement[]>([])
  const [activeProvider, setActiveProvider] = useState<ProviderType>('MetaMask')
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  const closeTermsOfService = () => setIsVisibleTermsOfService(false)

  const handleConnect = async (chain?: BlockchainType, provider?: ProviderType) => {
    try {
      await connect(chain || chainSelected, provider || activeProvider)
      localStorage.kephi_nft_terms = true
      closeTermsOfService()
    } catch (err) {
      console.log(err, 'connect')
    }
  }

  const openTermsOfService = (chain: BlockchainType, provider: ProviderType) => {
    if (!localStorage.kephi_nft_terms) {
      setActiveProvider(provider)

      setIsVisibleTermsOfService(true)
    } else {
      handleConnect(chain, provider)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  useEffect(() => {
    setFilteredProviders(
      providers.filter(provider => provider.chains.some(chain => chain.name === chainSelected)),
    )
  }, [chainSelected])

  return (
    <>
      <div className={styles.head}>
        <div className={styles.back}>
          <h2 className={cn('h2', styles.stage)}>{t('connect.title')}</h2>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.description}>
          <span>{t('connect.text1')}</span>{' '}
          <a
            className={styles.textActiveLink}
            href="https://kephigallery.medium.com/wallet-tutorial-111d7c7bfd82"
            target="_blank"
            rel="noreferrer">
            {t('connect.link')}
          </a>
        </p>
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            {chains
              .filter(
                chain =>
                  process.env.REACT_APP_POLYGON_ACTIVE === 'true' ||
                  (process.env.REACT_APP_POLYGON_ACTIVE === 'false' &&
                    chain.name !== BLOCKCHAIN.POLYGON),
              )
              .map(chain => (
                <button
                  className={styles.tabElement}
                  key={nextId()}
                  type="button"
                  onClick={() => setChainSelected(chain.name)}>
                  <div
                    className={cn(styles.tabTitle, {
                      [styles.active]: chain.name === chainSelected,
                    })}>
                    <div className={styles.tabIcon}>{chain.icon}</div>
                    {chain.name}
                  </div>
                  <input
                    className={styles.tabLine}
                    id={chain.name}
                    name={chain.name}
                    type="radio"
                  />
                </button>
              ))}
          </div>
          <ul className={styles.menu}>
            {filteredProviders.map(filteredProvider => (
              <li
                className={cn(styles.linkContainer, styles.animate, styles.fade)}
                key={nextId()}
                hidden={isMobileWidthResolution && filteredProvider.disabledOnMobile}>
                <button
                  className={cn(styles.link)}
                  type="button"
                  onClick={() => openTermsOfService(chainSelected, filteredProvider.provider)}>
                  <div className={styles.icon}>{filteredProvider.icon}</div>
                  <div className={styles.menuItemText}>
                    <div>{filteredProvider.title}</div>
                    {isMobileWidthResolution && !!filteredProvider.descriptionTranslation && (
                      <div className={styles.menuItemDescription}>
                        {`(${t(filteredProvider.descriptionTranslation)})`}
                      </div>
                    )}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <p className={styles.disclaimer}>{t('connect.text2')}</p>
      </div>

      <Modal visible={isVisibleTermsOfService} onClose={closeTermsOfService}>
        <TermsOfService
          handleConnect={() => handleConnect(activeChain, activeProvider)}
          handleCancel={closeTermsOfService}
        />
      </Modal>
    </>
  )
}

export default ChooseWallet
