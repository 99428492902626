import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {HelmetProvider} from 'react-helmet-async'
import {SkeletonTheme} from 'react-loading-skeleton'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import useDarkMode from 'use-dark-mode'

import Footer from './components/Footer'
import Header from './components/Header'
import Page from './components/Page/index'
import {LOCAL_STORAGE} from './constants/constants'
import Activity from './pages/Activity/index'
import AdvancedSearch from './pages/AdvancedSearch/AdvancedSearch'
import Collection from './pages/CollectionPage/index'
import ConnectWallet from './pages/ConnectWallet/index'
import CreateItem from './pages/CreateItem/CreateItem'
import Home from './pages/Home/index'
import Item from './pages/Item/index'
import NoResultsFound from './pages/NoResultsFound/NoResultsFound'
import Player from './pages/Player'
import Profile from './pages/Profile'
import ProfileEdit from './pages/ProfileEdit/index'
import UploadDetails from './pages/UploadDetails'
import UploadVariants from './pages/UploadVariants/index'
import Connector from './services/walletConnect'
import {Provider, rootStore} from './store/store'
import myLocalStorage from './utils/myLocalStorage'

import './styles/app.scss'

function App(): JSX.Element {
  const {value: isDarkModeOn} = useDarkMode()
  const [currentPath, setCurrentPath] = useState<string>('')

  useEffect(() => {
    setCurrentPath(window.location.pathname)
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>BeatBlox</title>
        <meta content="Music Token Gallery" name="description" />
        <meta property="og:title" content="BeatBlox" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Music Token Gallery" />
        <meta property="og:image" content="/logo-beatblox.png" />
        <meta property="og:image:alt" content="Card" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="800" />
        {/* Twitter */}
        <meta name="twitter:title" content="BeatBlox" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Music Token Gallery" />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo-beatblox.png" />
      </Helmet>
      <Provider value={rootStore}>
        <SkeletonTheme
          baseColor={
            isDarkModeOn && !!myLocalStorage.get(LOCAL_STORAGE.DARK_MODE) ? '#353945' : '#e6e8ec'
          }
          highlightColor={
            isDarkModeOn && !!myLocalStorage.get(LOCAL_STORAGE.DARK_MODE) ? '#777e91' : '#f4f5f6'
          }>
          <Router>
            <Connector>
              <Page />
              <Header />
              <div className="content">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route
                    path="/upload-variants"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <UploadVariants />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/create-item-single"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <CreateItem isSingle />
                      ) : (
                        <Redirect to="/upload-variants" />
                      )
                    }}
                  />

                  <Route
                    path="/create-item-multiple"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <CreateItem isSingle={false} />
                      ) : (
                        <Redirect to="/upload-variants" />
                      )
                    }}
                  />
                  <Route
                    path="/upload-details-single"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <UploadDetails isSingle />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/upload-details-multiple"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <UploadDetails isSingle={false} />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />

                  <Route path="/connect-wallet" component={ConnectWallet} />
                  <Route
                    path="/activity"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <Activity />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route path={['/profile/:userId/:tab', '/profile/:userId']} component={Profile} />
                  <Route
                    path="/profile-edit"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <ProfileEdit />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/game"
                    component={() => {
                      window.location.href = 'https://play.kephi.io/'
                      return null
                    }}
                  />
                  <Route path="/item/:itemId" component={Item} />
                  <Route path="/collection/:collectionId" component={Collection} />
                  <Route path="/advanced-search" component={AdvancedSearch} />
                  <Route path="/no-results-found" component={NoResultsFound} />
                  <Route path="/player" component={Player} />
                </Switch>
              </div>
              {currentPath !== '/player' && <Footer />}
            </Connector>
          </Router>
        </SkeletonTheme>
      </Provider>
    </HelmetProvider>
  )
}

export default App
