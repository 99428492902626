import {ETHAuth} from '@0xsequence/ethauth'
import {SignInOption} from '@0xsequence/provider'
import {IConnect, IError} from '@amfi/connect-wallet/dist/interface'
import {sequence} from '0xsequence'
import BigNumber from 'bignumber.js/bignumber'
import {ethers} from 'ethers'
import types from 'web3'
import {TransactionReceipt} from 'web3-core'

import {BLOCKCHAIN, LOCAL_STORAGE} from '../../constants/constants'
import {rootStore} from '../../store/store'
import {BlockchainType} from '../../types/blockchain'
import {ContractParam} from '../../types/contract'
import {ProviderType} from '../../types/provider'
import {formatCoinToken, isAnyOfMainCoin} from '../../utils/coins'
import Config from '../../utils/config'
import {getContractType} from '../../utils/contracts'
import myLocalStorage from '../../utils/myLocalStorage'
import {userApi} from '../api'
import {IWallet} from '../walletInterface'
import {Wallet} from '../walletService'

export const WALLET_CLOSE = 'WALLET_CLOSE'

export class Web2Wallet implements IWallet {
  public wallet: sequence.Wallet

  public walletAddress = ''

  constructor() {
    this.wallet = new sequence.Wallet()
  }

  // eslint-disable-next-line class-methods-use-this
  Web3(): types {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  encodeFunctionCall(abi: any, data: any[]): string {
    throw new Error('Method not implemented.')
  }

  public async initWalletConnect(
    chainName: BlockchainType,
    providerName: ProviderType,
  ): Promise<boolean> {
    const defautlWalletNetwork =
      chainName && providerName && Config.isProduction ? 'polygon' : 'mumbai'
    this.wallet = await (Config.isProduction
      ? sequence.initWallet(defautlWalletNetwork)
      : sequence.initWallet(defautlWalletNetwork, {
          networkRpcUrl: 'https://matic-mumbai.chainstacklabs.com',
        }))

    return this.wallet.isConnected()
  }

  public async connectWallet(
    chainName: BlockchainType,
    providerName: ProviderType,
    signInOption?: SignInOption,
  ): Promise<boolean> {
    const isConnected = await this.initWalletConnect(chainName, providerName)
    if (isConnected) {
      if (!this.walletAddress) {
        this.wallet = await sequence.getWallet()
        this.setAccountAddress(await this.wallet.getAddress())
      }
      return false
    }

    const connectDetails = await this.wallet.connect({
      app: 'Beatblox',
      authorize: true,
      keepWalletOpened: true,
      refresh: true,
      settings: {
        theme: 'mauveDark',
        bannerUrl: `${window.location.origin}/images/logos/white-beatblox-logo.png`,
        includedPaymentProviders: ['wyre'],
        defaultFundingCurrency: chainName === BLOCKCHAIN.POLYGON ? 'matic' : 'eth',
        lockFundingCurrencyToDefault: false,
        ...((signInOption && {
          signInOptions: [signInOption],
        }) || {signInOptions: ['google', 'facebook', 'apple', 'email']}),
      },
    })
    if (!connectDetails?.connected) throw new Error(WALLET_CLOSE)

    const address = await this.wallet.getAddress()
    if (rootStore.user.address && address !== rootStore.user.address) {
      this.disconnect()
      return false
    }

    if (!localStorage.beatblox_nft_token) {
      const signer = await this.wallet.getSigner()
      const metMsg: any = await userApi.getMsg()
      const signedMsg = await signer.signMessage(metMsg.data)

      if (connectDetails.proof) {
        const ethAuth = new ETHAuth()
        const decodedProof = await ethAuth.decodeProof(connectDetails.proof.proofString, true)

        const isValid = await this.wallet.utils.isValidTypedDataSignature(
          address,
          connectDetails.proof.typedData,
          decodedProof.signature,
          await this.wallet.getAuthChainId(),
        )

        if (!isValid) throw new Error('sig invalid')
      }

      const login: any = await userApi.login({
        address,
        msg: metMsg.data,
        signedMsg,
        referredBy: myLocalStorage.get(LOCAL_STORAGE.REFERRAL_CODE),
      })

      localStorage.beatblox_nft_token = login.data.key
      localStorage.kephi_nft_token = login.data.key
      this.setAccountAddress(address)
      return true
    }
    return false
  }

  public disconnect(): void {
    this.wallet.disconnect()
    localStorage.removeItem('@sequence.session')
    localStorage.removeItem('@sequence.connectedSites')
    rootStore.user.disconnect()
  }

  public logOut(): void {
    this.wallet.disconnect()
  }

  public async signMessage(msg: string): Promise<string> {
    const signer = await this.wallet.getSigner()
    return signer.signMessage(msg)
  }

  public async getTokenBalance(contractAbi: ContractParam): Promise<string | number> {
    if (!this.walletAddress) {
      this.wallet = await sequence.getWallet()
      this.setAccountAddress(await this.wallet.getAddress())
    }
    // const network = await this.wallet.getAuthNetwork()
    // console.log('indexerUrl', network?.indexerUrl)
    const indexer = new sequence.indexer.SequenceIndexerClient(
      Config.isProduction
        ? sequence.indexer.SequenceIndexerServices.POLYGON
        : sequence.indexer.SequenceIndexerServices.POLYGON_MUMBAI,
    )

    if (isAnyOfMainCoin(contractAbi)) {
      const etherBalance = await indexer.getEtherBalance({
        accountAddress: this.walletAddress,
      })
      return etherBalance?.balance?.balanceWei
    }

    const tokenBalances = await indexer.getTokenBalances({
      accountAddress: this.walletAddress,
      includeMetadata: true,
    })
    const amountBalance =
      tokenBalances?.balances.find(
        tokenBalance =>
          tokenBalance.contractAddress.toLowerCase() ===
          rootStore.contracts.params[formatCoinToken(contractAbi)][
            getContractType()
          ].address.toLowerCase(),
      )?.balance || 0
    return amountBalance
  }

  public setAccountAddress(address: string): void {
    this.walletAddress = address
    rootStore.user.setAddress(address)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async checkNftTokenAllowance(collectionAddress: string): Promise<string> {
    console.log(collectionAddress)
    if (!this.walletAddress) {
      this.wallet = await sequence.getWallet()
      this.setAccountAddress(await this.wallet.getAddress())
    }

    const result = new ethers.utils.Interface(
      rootStore.contracts.params.NFT[getContractType()].abi,
    ).encodeFunctionData('isApprovedForAll', [
      this.walletAddress,
      rootStore.contracts.params.EXCHANGE[getContractType()].address,
    ])

    return result
  }

  public getAccount(): Promise<IConnect | IError | {address: string}> {
    return new Promise(resolve => resolve({address: this.walletAddress}))
  }

  static getMethodInterface(abi: Array<any>, methodName: string) {
    return abi.filter(m => {
      return m.name === methodName
    })[0]
  }

  async createTransaction(
    method: string,
    data: Array<any>,
    contract: ContractParam,
    tx?: any,
    tokenAddress?: string,
    walletAddress?: string,
    value?: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    this.wallet = await sequence.getWallet()
    const signer = this.wallet.getSigner()

    const sequenceTx: sequence.transactions.Transaction = {
      delegateCall: false,
      revertOnError: false,
      // nonce: tx?.nonce,
      to: tx?.to || tokenAddress,
      value: tx?.value || value,
      data: new ethers.utils.Interface(
        rootStore.contracts.params[contract][getContractType()].abi,
      ).encodeFunctionData(method, data),
    }

    return signer.sendTransactionBatch([sequenceTx])
  }

  async sendTransaction(
    transactionConfig: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    this.wallet = await sequence.getWallet()
    const signer = this.wallet.getSigner()

    const tx: sequence.transactions.Transaction = {
      delegateCall: false,
      revertOnError: false,
      // gasLimit: transactionConfig.gasLimit,
      to: transactionConfig.to,
      // nonce: transactionConfig.nonce,
      data: transactionConfig.data,
      value: transactionConfig.value,
    }

    return signer.sendTransactionBatch([tx])
  }

  async totalSupply(tokenAddress: string, abi: Array<any>, tokenDecimals: number): Promise<number> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const contract = this.wallet.getContract({
      address: tokenAddress,
      abi,
    })
    const totalSupply = await contract.methods.totalSupply().call()

    return +new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(tokenDecimals)).toString(10)
  }

  async checkTokenAllowance(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<boolean> {
    try {
      if (!walletAddress && !this.walletAddress) {
        this.wallet = await sequence.getWallet()
        this.setAccountAddress(await this.wallet.getAddress())
      }

      let totalSupply = 0
      let approvedMoneyToSpend: string | number = '0'

      const walletAdr = walletAddress || this.walletAddress || ''

      approvedMoneyToSpend = new ethers.utils.Interface(
        rootStore.contracts.params[contractName][getContractType()].abi,
      ).encodeFunctionData('allowance', [
        walletAdr,
        exchangeAddress || rootStore.contracts.params[contractName][getContractType()].address,
      ])

      const network = await this.wallet.getAuthNetwork()
      const indexer = new sequence.indexer.SequenceIndexerClient(
        network?.indexerUrl || sequence.indexer.SequenceIndexerServices.POLYGON_MUMBAI,
      )

      const tokenDetails = await indexer.getTokenSupplies({
        // contractAddress: walletAdr,
        contractAddress: rootStore.contracts.params[contractName][getContractType()].address,
        includeMetadata: true,
      })

      totalSupply = +tokenDetails.tokenIDs[0]?.supply || 0

      approvedMoneyToSpend =
        approvedMoneyToSpend === '0'
          ? ''
          : +new BigNumber(approvedMoneyToSpend)
              .dividedBy(new BigNumber(10).pow(tokenDecimals))
              .toString(10)
      if (
        !!approvedMoneyToSpend &&
        new BigNumber(approvedMoneyToSpend).minus(totalSupply).isPositive()
      ) {
        return true
      }
      return false
    } catch (error) {
      console.log('checkTokenAllowanceError', error)
      return false
    }
  }

  async approveToken(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<unknown> {
    try {
      if (!walletAddress && !this.walletAddress) {
        this.wallet = await sequence.getWallet()
        this.setAccountAddress(await this.wallet.getAddress())
      }

      const signer = this.wallet.getSigner()

      const approveSignature = new ethers.utils.Interface(
        rootStore.contracts.params[contractName][getContractType()].abi,
      ).encodeFunctionData('approve', [
        exchangeAddress || walletAddress || this.walletAddress,
        // Wallet.calcTransactionAmount(90071992000.5474099, tokenDecimals),
        Wallet.calcTransactionAmount(amount, tokenDecimals),
      ])

      const tx: sequence.transactions.Transaction = {
        delegateCall: false,
        revertOnError: false,
        to: rootStore.contracts.params[contractName][getContractType()].address,
        data: approveSignature,
      }

      return signer.sendTransactionBatch([tx])
    } catch (error) {
      return error
    }
  }
}
