import {Fragment, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {message} from 'antd'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import {ethers} from 'ethers'

import Modal from '../../../../components/Modal'
import Successfully from '../../../../components/Successfully/Successfully'
import {PROVIDER} from '../../../../constants/constants'
import {storeApi} from '../../../../services/api'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {Wallet} from '../../../../services/walletService'
import {useMst} from '../../../../store/store'
import {getCoinDecimalsByName} from '../../../../utils/coins'
import {defaultData, ICreateFormData} from '../../types/CreateFormData'
import {IStepType} from '../../types/Step'
import {IStepperType} from '../../types/Stepper'
import Preview from '../Preview'

import styles from './Stepper.module.scss'

message.config({
  top: 85,
})

const Stepper: React.FC<IStepperType> = ({steps, isSingle}) => {
  const history = useHistory()
  const {user} = useMst()
  const {t} = useTranslation()
  const walletConnector = useWalletConnectorContext()
  const getActiveStepByNumber = (number: number) => steps.filter(step => step.number === number)[0]
  const [activeStep, setActiveStep] = useState<IStepType>(getActiveStepByNumber(1))
  const [swipeToRight, setSwipeToRight] = useState<boolean>(true)
  const [data, setData] = useState<ICreateFormData>(defaultData)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [tokenId, setTokenId] = useState<string | number>()

  const handleLastStep = () => {
    if (activeStep.number > 1) {
      if (swipeToRight) setSwipeToRight(false)
      setActiveStep(getActiveStepByNumber(activeStep.number - 1))
    }
  }
  const handleNextStep = () => {
    if (steps.length > activeStep.number) {
      if (!swipeToRight) setSwipeToRight(true)
      setActiveStep(getActiveStepByNumber(activeStep.number + 1))
    } else if (steps.length === activeStep.number) {
      setShowPreview(true)
    }
  }
  const onSubmit = (parcialData: any) => {
    setData({...data, ...parcialData})
  }
  const onClear = () => history.push(`/item/${tokenId}`)

  const onCreateNft = () => {
    setLoading(true)

    const createFormData = new FormData()
    createFormData.append('media', data.img)
    if (data.cover) createFormData.append('cover', data.cover)
    createFormData.append('name', data.name)
    createFormData.append('total_supply', isSingle ? '1' : data.total_supply.toString())
    createFormData.append('description', data.description)
    createFormData.append('beneficiary', data.beneficiary)
    if (data.instantSalePrice && data.putOnSale) {
      createFormData.append('price', data.instantSalePriceEth.toString())
    }
    if (!data.instantSalePrice && data.putOnSale) {
      createFormData.append('minimal_bid', data.bid.toString())
    }
    if (data.putOnSale) {
      createFormData.append('available', data.total_supply.toString())
    } else {
      createFormData.append('available', '0')
    }
    createFormData.append('creator_royalty', data.tokenRoyalties)
    createFormData.append('standart', isSingle ? 'ERC721' : 'ERC1155')
    createFormData.append('collection', data.collectionId)
    createFormData.append('currency', data.currency)
    createFormData.append('format', data.format.toUpperCase())
    createFormData.append('tag', data.tag.toUpperCase())
    createFormData.append('selling', data.putOnSale.toString())
    if (data.unlockOncePurchased) {
      createFormData.append('digital_key', data.digitalKey)
    }

    if (data.tokenProperties[0].size) {
      const details: any = {}
      data.tokenProperties.forEach(item => {
        if (item.size) {
          details[item.size] = item.amount
        }
      })

      createFormData.append('details', JSON.stringify(details))
    }

    storeApi
      .createToken(createFormData)
      .then(({data: createData}: any) => {
        createData.initial_tx.gasPrice *= 2

        // PARCHE - TODO: Delegar en el SendTransaction
        createData.initial_tx.value =
          localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE
            ? ethers.utils.parseEther(
                Wallet.weiToEth(createData.initial_tx.value, getCoinDecimalsByName(data.currency)),
              )
            : new BigNumber(createData.initial_tx.value).toFixed(0)

        walletConnector.walletService
          .sendTransaction(createData.initial_tx)
          .then((response: any) => {
            setLoading(false)
            setShowPreview(false)
            setShowSuccess(true)
            storeApi.trackMinerTransaction(
              createData.token.id,
              response.transactionHash || response.hash,
              response.blockNumber,
              'Mint',
            )
            setTokenId(createData.token.id)
          })
          .catch((err: any) => {
            console.log(err, 'err', 'data', createData)
            storeApi.rejectTransaction({
              type: 'token',
              id: createData.token.id,
            })
            setLoading(false)
          })
      })
      .catch(error => {
        const {response} = error
        setLoading(false)
        if (!!response?.data && !!response?.data?.name) {
          message.error({
            content: t('create_form.duplicatedName'),
            className: styles.errorMessage,
          })
          setData({...data, isDuplicateName: true})
          setShowPreview(false)
          setActiveStep(getActiveStepByNumber(2))
        }
        if (response?.data) {
          console.log('CreatingNFTErrorData:', response)
        }
        if (error) console.log('CreatingNFTError:', error)
      })
  }

  const isFirstStep = activeStep.number === 1
  const isLastStep = activeStep.number === steps.length
  const isActiveStep = useCallback((number: number) => activeStep.number === number, [activeStep])
  const isStepDone = useCallback((number: number) => activeStep.number > number, [activeStep])

  return (
    <>
      <div className={styles.stepperContainer}>
        <div className={styles.stepsContainer}>
          {steps.map(step => (
            <Fragment key={step.number}>
              <div
                className={classNames(styles.separatorLine, {
                  [styles.show]:
                    isActiveStep(step.number) &&
                    !isFirstStep &&
                    swipeToRight &&
                    activeStep.number === 2,
                  [styles.showRight]:
                    isActiveStep(step.number) &&
                    !isFirstStep &&
                    swipeToRight &&
                    activeStep.number !== 2,
                  [styles.showLeft]: isActiveStep(step.number) && !isFirstStep && !swipeToRight,
                })}
              />
              <div
                className={classNames(styles.stepNumber, {
                  [styles.active]: isActiveStep(step.number),
                  [styles.done]: isStepDone(step.number),
                })}>
                {step.number}
              </div>
              <div
                className={classNames(styles.separatorLine, {
                  [styles.show]:
                    isActiveStep(step.number) &&
                    !isLastStep &&
                    !swipeToRight &&
                    activeStep.number === 4,
                  [styles.showRight]: isActiveStep(step.number) && !isLastStep && swipeToRight,
                  [styles.showLeft]:
                    isActiveStep(step.number) &&
                    !isLastStep &&
                    !swipeToRight &&
                    activeStep.number !== 4,
                })}
              />
            </Fragment>
          ))}
        </div>
        {activeStep.form(
          isFirstStep ? undefined : handleLastStep,
          handleNextStep,
          onSubmit,
          data,
          isSingle,
        )}
      </div>
      {showPreview && (
        <Modal visible={showPreview} onClose={() => setShowPreview(false)} closeOnRight>
          <Preview
            className={classNames(styles.preview)}
            mediaURL={
              data.format === 'IMAGE' || data.format === 'PHOTOGRAPHY' || data.format === 'POETRY'
                ? data.preview
                : data.coverPreview
            }
            name={data.name}
            price={data.instantSalePriceEth.toString()}
            format={data.format}
            onClear={onClear}
            currency={data.currency.toUpperCase()}
            bid={data.bid}
            amount={data.total_supply.toString()}
            avatar={user.avatar || ''}
            onCreateNft={onCreateNft}
            isLoading={loading}
          />
        </Modal>
      )}
      {showSuccess && (
        <Modal
          outerClassName={styles.modalSuccessOuter}
          visible={showSuccess}
          onClose={onClear}
          closeButtonHidden>
          <Successfully
            onClose={onClear}
            titleText={t('token.mintSuccessTitle')}
            subtitleText={t('token.mintSuccessSubtitle')}
            infoText={t('token.note')}
            closeText={t('token.seeMyNft')}
          />
        </Modal>
      )}

      {loading && <div className={styles.parentDisable} />}
    </>
  )
}

export default Stepper
