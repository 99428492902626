export const splitSongAndArtistName = (title: string): {artist: string; songTitle: string} => {
  const [artist, ...songParts] = title.split(' - ')
  const songTitle = songParts.join(' - ')
  return {artist: artist.trim(), songTitle: songTitle.trim()}
}

export const formatSongTime = (time: number): string => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}
