import React, {useCallback, useEffect, useState} from 'react'
import classNames from 'classnames'

import {filterIcons as filterIconsConstant} from '../../../../../../../constants/filterIcons'
import {storeApi} from '../../../../../../../services/api'

import styles from './FilterIcons.module.scss'

interface IFilterIconsProps {
  className?: string
  onChangeFilterIcon: (type: string) => void
  filterIconSelected: string | number
  withoutAllItemsIcon?: boolean
  onlyExistingFilters?: boolean
  error?: boolean
}

const FilterIcons = React.memo<IFilterIconsProps>(
  ({
    className,
    onChangeFilterIcon,
    filterIconSelected,
    withoutAllItemsIcon,
    onlyExistingFilters,
    error,
  }) => {
    const [filterIcons, setFilterIcons] = useState<any[]>([])
    const fetchTags = useCallback(async () => {
      let filteredIcons = filterIconsConstant
      if (onlyExistingFilters) {
        const links = await storeApi.getTags()
        filteredIcons = filteredIcons.filter(filterIcon =>
          links.data.tags.some(
            (link: string) => link === filterIcon.label || filterIcon.label === 'ALL ITEMS',
          ),
        )
      }
      if (withoutAllItemsIcon)
        filteredIcons = filteredIcons.filter(filterIcon => filterIcon.label !== 'ALL ITEMS')
      setFilterIcons(filteredIcons)
    }, [withoutAllItemsIcon, onlyExistingFilters])

    useEffect(() => {
      fetchTags()
    }, [fetchTags])

    return (
      <div className={classNames(styles.filtersContainer, className)}>
        {filterIcons.map(filterIcon => (
          <button
            key={filterIcon.id}
            type="button"
            onClick={() => onChangeFilterIcon(filterIcon.label)}
            className={classNames(styles.filterButton, {
              [styles.active]: filterIcon.label === filterIconSelected,
              [styles.error]: error,
            })}>
            <img src={filterIcon.iconUrl} alt="icon" />
          </button>
        ))}
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filterIconSelected === nextProps.filterIconSelected &&
      prevProps.error === nextProps.error
    )
  },
)

export default FilterIcons
