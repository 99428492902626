import React, {useEffect, useState} from 'react'
import cn from 'classnames'

import {BlockchainType} from '../../types/blockchain'

import ChooseBlockchain from './ChooseBlockchain/ChooseBlockchain'
import ChooseWallet from './ChooseWallet/ChooseWallet'

import styles from './ConnectWallet.module.scss'

const MAX_WIDTH_MOBILE = 1179

const ConnectWallet: React.FC = () => {
  const [activeChain, setActiveChain] = useState<BlockchainType | undefined>('Polygon')
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        {!activeChain ? (
          <ChooseBlockchain setActiveChain={setActiveChain} />
        ) : (
          <ChooseWallet activeChain={activeChain} />
        )}
      </div>
    </div>
  )
}

export default ConnectWallet
