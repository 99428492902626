import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'

import 'moment/locale/es'

import Dropdown from '../Dropdown'
import Icon, {IIcons} from '../Icon'
import Image from '../Image'
import Theme from '../Theme'

import styles from './Footer.module.scss'

const languages = ['EN', 'ES']

const socials: {title: IIcons; url: string; viewBox: string; size: string}[] = [
  {
    title: 'twitter-fill',
    url: 'https://twitter.com/The_Beatblox',
    viewBox: '0 0 25 21',
    size: '22',
  },
  {
    title: 'instagram',
    url: 'https://www.instagram.com/thebeatblox/',
    viewBox: '0 0 16 20',
    size: '30',
  },
  {
    title: 'youtube-fill',
    url: 'https://www.youtube.com/channel/UCo2dvKd4KcvURIVIesL9NZQ',
    viewBox: '0 0 22 22',
    size: '22',
  },
  {
    title: 'discord-fill',
    url: 'https://discord.gg/g4kqxe9PA4',
    viewBox: '0 0 22 15',
    size: '22',
  },
]

const Footers: React.FC = () => {
  const [language, setLanguage] = useState(
    localStorage.kephi_lang && localStorage.kephi_lang === 'es' ? languages[1] : languages[0],
  )
  // const [isShowCoockie, setShowCoockie] = useState(!localStorage.kephi_nft_coockie)

  const {t, i18n} = useTranslation()

  const handleChangeLang = (lang: any) => {
    setLanguage(lang)
    if (lang === 'EN') {
      i18n.changeLanguage('en')
      localStorage.kephi_lang = 'en'
      moment.locale('en')
    } else {
      i18n.changeLanguage('es')
      localStorage.kephi_lang = 'es'
      moment.locale('es')
    }
  }

  // const hancleAcceptCoockie = useCallback(() => {
  //   localStorage.kephi_nft_coockie = true
  //   setShowCoockie(false)
  // }, [])

  useEffect(() => {
    if (localStorage.kephi_lang) {
      i18n.changeLanguage(localStorage.kephi_lang)
      moment.locale(localStorage.kephi_lang)
      setLanguage(localStorage.kephi_lang === 'es' ? languages[1] : languages[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, localStorage.kephi_lang])

  return (
    <footer className={styles.footer}>
      <div className={cn('container', styles.container)}>
        <div className={cn(styles.row, styles.wrapper)}>
          <div
            className={cn(styles.col, {
              [styles.colCenter]: process.env.REACT_APP_DARK_THEME_ACTIVE !== 'true',
            })}>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <Link to="/">
                  <Image
                    src="/images/logos/black-beatblox-logo.png"
                    srcSetDark="/images/logos/white-beatblox-logo.png"
                    alt="Footer logo"
                  />
                </Link>
              </div>
              <div className={styles.description}>{t('footer.slogan')}</div>
            </div>
            {process.env.REACT_APP_DARK_THEME_ACTIVE === 'true' && (
              <div className={styles.themeContainer}>
                <div className={styles.themeText}>{t('darkTheme')}</div>
                <Theme className={styles.theme} />
              </div>
            )}
          </div>

          <div className={styles.linkGroups}>
            <div className={cn(styles.col, styles.colMargin)}>
              <div className={styles.socials}>
                {socials.map(social => (
                  <a
                    key={JSON.stringify(social)}
                    className={styles.social}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name={social.title} size={social.size} viewBox={social.viewBox} />
                  </a>
                ))}
              </div>
            </div>
            <div className={cn(styles.col, styles.colMargin)}>
              <div className={styles.dropdownWrapper}>
                <Dropdown
                  className={styles.dropdown}
                  setValue={(selectedStr: any) => handleChangeLang(selectedStr)}
                  value={language}
                  options={languages}
                  customClasses={{
                    head: styles.languageDropdownHead,
                    arrow: styles.languageDropdownArrow,
                  }}
                />
              </div>
            </div>
          </div>

          {/* <Image
          className={styles.socials}
          src="/images/socials_remove_me.svg"
          alt="socials remove me"
        /> */}
        </div>

        <div className={styles.divider} />
        <div className={styles.rowFooter}>
          <div className={styles.copyright}>{t('footer.copy')}</div>
          <span className={styles.poweredBy}>
            {t('footer.poweredBy')}
            <Image src="/images/logos/blockchain-solutions.svg" alt="Footer logo" />
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footers
