import {useEffect, useRef, useState} from 'react'
import {IoMdVolumeHigh} from 'react-icons/io'

import {formatSongTime} from '../../../utils/format'

import styles from './PlayerControls.module.scss'

function PlayIconSVG() {
  return (
    <svg
      fill="#000000"
      height="30px"
      width="30px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 17.804 17.804"
      xmlSpace="preserve">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <g id="c98_play">
            <path d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313 c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04 c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z" />
          </g>
          <g id="Capa_1_78_" />
        </g>
      </g>
    </svg>
  )
}

function PauseIconSVG() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 512 512" fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#000000"
          d="M120.16 45A20.162 20.162 0 0 0 100 65.16v381.68A20.162 20.162 0 0 0 120.16 467h65.68A20.162 20.162 0 0 0 206 446.84V65.16A20.162 20.162 0 0 0 185.84 45h-65.68zm206 0A20.162 20.162 0 0 0 306 65.16v381.68A20.162 20.162 0 0 0 326.16 467h65.68A20.162 20.162 0 0 0 412 446.84V65.16A20.162 20.162 0 0 0 391.84 45h-65.68z"
        />
      </g>
    </svg>
  )
}

type PlayerControlsProps = {
  nextSong: any
  currentSong: string | undefined
  currentSongDetails: {
    artist: string
    songTitle: string
  }
  setScoreEarned: any
  updateCurrentCollection: any
  isPlaying: boolean
  setIsPlaying: any
}

const PlayerControls: React.FC<PlayerControlsProps> = ({
  nextSong,
  currentSong,
  currentSongDetails,
  setScoreEarned,
  updateCurrentCollection,
  isPlaying,
  setIsPlaying,
}: PlayerControlsProps) => {
  const [volume, setVolume] = useState(1)
  const [currentTime, setCurrentTime] = useState('0:00')
  const [duration, setDuration] = useState('0:00')
  const [secondsPlayed, setSecondsPlayed] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const audioRef = useRef<HTMLAudioElement>(null)
  const isFirstPlay = useRef(true)

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value)
    setVolume(newVolume)
  }

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying: boolean) => {
      const newIsPlaying = !prevIsPlaying
      if (audioRef.current) {
        if (newIsPlaying) {
          audioRef.current.play()
        } else {
          audioRef.current.pause()
        }
      }
      return newIsPlaying
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 580)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (audioRef.current) {
      if (isFirstPlay.current) {
        audioRef.current.src = currentSong || ''
        isFirstPlay.current = false
      } else {
        audioRef.current.play()
      }
    }
  }, [currentSong])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume
    }
  }, [volume])

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentAudioRef = audioRef.current
      if (currentAudioRef) {
        const {currentTime: nowTime} = currentAudioRef
        setCurrentTime(formatSongTime(nowTime))
        setSecondsPlayed(nowTime)
        // 1 second played = 5 points earned
        setScoreEarned(Math.floor(nowTime) * 5)
      }
    }

    const handleEnded = () => {
      updateCurrentCollection()
    }

    const currentAudioRef = audioRef.current
    if (currentAudioRef) {
      currentAudioRef.addEventListener('timeupdate', handleTimeUpdate)
      currentAudioRef.addEventListener('ended', handleEnded)
    }

    return () => {
      // eslint-disable-next-line
      const currentAudioRefCleanUp = audioRef.current;
      if (currentAudioRefCleanUp) {
        currentAudioRefCleanUp.removeEventListener('timeupdate', handleTimeUpdate)
        currentAudioRefCleanUp.removeEventListener('ended', handleEnded)
      }
    }
  }, [setSecondsPlayed, setScoreEarned, updateCurrentCollection])

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const currentAudioRef = audioRef.current
      if (currentAudioRef) {
        const {duration: newDuration} = currentAudioRef
        setDuration(formatSongTime(newDuration))
      }
    }

    const currentAudioRef = audioRef.current
    if (currentAudioRef) {
      currentAudioRef.addEventListener('loadedmetadata', handleLoadedMetadata)
    }

    return () => {
      // eslint-disable-next-line
      const currentAudioRefCleanUp = audioRef.current;
      if (currentAudioRefCleanUp) {
        currentAudioRefCleanUp.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [])

  return (
    <div className={styles.player__controls__container}>
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <audio ref={audioRef} src={currentSong} />

      <div className={styles.progress__bar__container}>
        <progress
          className={styles.progress__bar}
          value={secondsPlayed}
          max={audioRef.current?.duration || 0}
        />
        <div className={styles.progress__time}>
          <span>{currentTime}</span>
          <span>{duration}</span>
        </div>
      </div>

      {isMobile && (
        <>
          <div className={styles.player__info}>
            <span className={styles.player_track}>{currentSongDetails.songTitle}</span>
            <span className={styles.player_artist}>{currentSongDetails.artist}</span>
          </div>
          <div className={styles.player__volume__container}>
            <div className={styles.player__volume__box}>
              <IoMdVolumeHigh className={styles.player__volume_icon} size={26} />
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className={styles.player__volume__slider}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.player__controls}>
        {!isMobile ? (
          <div className={styles.player__info}>
            <span className={styles.player_track}>{currentSongDetails.songTitle}</span>
            <br />
            <span className={styles.player_artist}>{currentSongDetails.artist}</span>
          </div>
        ) : (
          // this is to keep the layout consistent
          <div />
        )}

        <div className={styles.player__controller}>
          <button type="button" className={styles.player__button} onClick={handlePlayPause}>
            {isPlaying ? <PauseIconSVG /> : <PlayIconSVG />}
          </button>
        </div>

        <div className={styles.player__volume__container}>
          {!isMobile && (
            <div className={styles.player__volume__box}>
              <IoMdVolumeHigh className={styles.player__volume_icon} size={26} />
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className={styles.player__volume__slider}
              />
            </div>
          )}
          {!isMobile && (
            <div className={styles.player__next_song}>
              {nextSong && (
                <>
                  <img src={nextSong.avatar} alt={nextSong.name} />
                  <span> Next: {nextSong.name}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={styles.player__next_song}>
          {nextSong && (
            <>
              <img src={nextSong.avatar} alt={nextSong.name} />
              <span> Next: {nextSong.name}</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default PlayerControls
