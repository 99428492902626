import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {SignInOption} from '@0xsequence/provider'
import classNames from 'classnames'

import {BLOCKCHAIN, PROVIDER} from '../../../../constants/constants'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {BlockchainType} from '../../../../types/blockchain'
import {ProviderType} from '../../../../types/provider'
import Modal from '../../../Modal'
import TermsOfService from '../../../TermsOfService'
import LogInOption from '../LogInOption/LogInOption'

import styles from './LogInOptions.module.scss'

interface ILogInOptions {
  className?: string
  onClose: () => void
}

const LogInOptions: React.FC<ILogInOptions> = ({className, onClose}) => {
  const history = useHistory()
  const {t} = useTranslation()
  const {connect} = useWalletConnectorContext()
  const [signInOptionSelected, setSignInOption] = useState<SignInOption>()
  const [isVisibleTermsOfService, setIsVisibleTermsOfService] = useState(false)

  const closeTermsOfService = () => setIsVisibleTermsOfService(false)

  const handleConnect = async (
    chain: BlockchainType,
    provider: ProviderType,
    logInOption?: SignInOption,
  ) => {
    try {
      await connect(chain, provider, logInOption)
      localStorage.beatblox_nft_terms = true
      if (isVisibleTermsOfService) closeTermsOfService()
    } catch (err) {
      console.log(err, 'connect')
    }
  }

  const openTermsOfService = (logInOption: SignInOption) => {
    setSignInOption(logInOption)
    if (!localStorage.beatblox_nft_terms) {
      setIsVisibleTermsOfService(true)
    } else {
      handleConnect(BLOCKCHAIN.POLYGON, PROVIDER.SEQUENCE, logInOption)
    }
  }

  const logInOptions = [
    <LogInOption
      key="1"
      label={t('logIn.wallet')}
      logoUrl="/images/icons/login/wallet.svg"
      onClick={() => {
        onClose()
        history.push('/connect-wallet')
      }}
    />,

    <LogInOption
      key="2"
      label="Google"
      logoUrl="/images/icons/login/google.svg"
      onClick={() => openTermsOfService('google')}
    />,
    <LogInOption
      key="3"
      label="Facebook"
      logoUrl="/images/icons/login/facebook.svg"
      onClick={() => openTermsOfService('facebook')}
    />,
    <LogInOption
      key="4"
      label="Apple"
      logoUrl="/images/icons/login/apple.svg"
      onClick={() => openTermsOfService('apple')}
    />,
    <LogInOption
      key="5"
      label="Email"
      logoUrl="/images/icons/login/email.svg"
      onClick={() => openTermsOfService('email')}
    />,
  ]

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.rowContainer}>
        <div className={styles.title}>{t('logIn.title')}</div>
      </div>
      <div className={classNames(styles.rowContainer, styles.noBorder)}>
        <div className={styles.columnRows}>
          <div className={styles.connectContainer}>
            <div className={styles.label}>{t('logIn.subtitle')}</div>
            <div className={styles.description}>{t('logIn.description')}</div>
          </div>
          <div className={styles.rowFlex}>{logInOptions.map(option => option)}</div>
        </div>
      </div>
      <Modal visible={isVisibleTermsOfService} onClose={closeTermsOfService} closeOnRight>
        <TermsOfService
          handleConnect={() =>
            handleConnect(BLOCKCHAIN.POLYGON, PROVIDER.SEQUENCE, signInOptionSelected)
          }
          handleCancel={closeTermsOfService}
        />
      </Modal>
    </div>
  )
}

export default LogInOptions
