import React, {useCallback} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {message} from 'antd'
import classNames from 'classnames'

import ClearImg from '../../../../../assets/img/icons/uploader-cross.svg'
import Uploader from '../../../../../components/Uploader'
import {ICreateForm} from '../../../types/Stepper'

import styles from './UploadFile.module.scss'

const COVER = 'cover'
const COVER_PREVIEW = 'coverPreview'
const IMG = 'img'
const FORMAT = 'format'
const PREVIEW = 'preview'

message.config({
  top: 85,
})

export type UploadFileForm = {
  cover: string
  coverPreview: string
  img: string
  format: string
  preview: string
  tag: string
}

const UploadFileStep: React.FC<ICreateForm> = ({lastStep, nextStep, onSubmit, currentData}) => {
  const {t} = useTranslation()

  const {handleSubmit, watch, register, control, setValue} = useForm<UploadFileForm>({
    defaultValues: {
      cover: currentData.cover,
      coverPreview: currentData.coverPreview,
      img: currentData.img,
      format: currentData.format,
      preview: currentData.preview,
    },
  })

  const [cover, coverPreview, img, format, preview] = watch([
    COVER,
    COVER_PREVIEW,
    IMG,
    FORMAT,
    PREVIEW,
  ])

  const renderCover = useCallback(() => {
    return (
      <div className={styles.item}>
        {cover ? (
          <div className={styles.previewImg}>
            <img src={coverPreview} alt="Preview" />
            <div
              className={styles.clear}
              onClick={() => {
                setValue(COVER, '')
                setValue(COVER_PREVIEW, '')
              }}
              onKeyDown={() => {
                setValue(COVER, '')
                setValue(COVER_PREVIEW, '')
              }}
              role="button"
              tabIndex={0}>
              <img src={ClearImg} alt="" />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.title}>{t('create_form.upload_preview')}</div>
            <div className={styles.subtitle}>{t('create_form.upload_drag')}</div>
            <div className={styles.file}>
              <Controller
                control={control}
                name={COVER}
                rules={{
                  required: {
                    value: !!img,
                    message: t('create_form.uploadChooseFileCover'),
                  },
                }}
                render={({field: {name, ...rest}}) => (
                  <Uploader
                    className={styles.load}
                    type={COVER}
                    setFormat={(value: string) => setValue(FORMAT, value.toLocaleUpperCase())}
                    setFieldValue={(key: any, value: any) => setValue(key, value)}
                    {...rest}
                    {...register(name, {
                      validate: value => value !== '',
                    })}
                  />
                )}
              />
              <div className={styles.icon}>
                <img alt="" src="/images/content/icon-upload.svg" />
              </div>
              <div className={styles.format}>{t('create_form.upload_text2')}</div>
            </div>
          </>
        )}
      </div>
    )
  }, [control, cover, coverPreview, img, register, setValue, t])

  const renderImg = useCallback(() => {
    return (
      <div className={styles.item}>
        {img ? (
          <div className={styles.previewImg}>
            {format !== 'VIDEO' && format !== 'AUDIO' && <img src={preview} alt="Media" />}
            {format === 'VIDEO' && (
              <video controls className={styles.controls}>
                <source src={preview} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                <track kind="captions" />
              </video>
            )}
            {format === 'AUDIO' && (
              <audio controls className={styles.controls}>
                <source src={preview} />
                <track kind="captions" />
              </audio>
            )}
            <div
              className={styles.clear}
              onClick={() => {
                setValue(IMG, '')
                setValue(PREVIEW, '')
                setValue(FORMAT, '')
              }}
              onKeyDown={() => {
                setValue(IMG, '')
                setValue(PREVIEW, '')
                setValue(FORMAT, '')
              }}
              role="button"
              tabIndex={0}>
              <img src={ClearImg} alt="" />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.title}>{t('create_form.upload_file')}</div>
            <div className={styles.subtitle}>{t('create_form.upload_drag')}</div>
            <div className={styles.file}>
              <Controller
                control={control}
                name={IMG}
                rules={{
                  required: {
                    value: true,
                    message: t('create_form.uploadChooseFile'),
                  },
                }}
                render={({field: {...rest}}) => (
                  <Uploader
                    className={styles.load}
                    type={IMG}
                    setFormat={(value: string) => setValue(FORMAT, value.toLocaleUpperCase())}
                    setFieldValue={(key: any, value: any) => setValue(key, value)}
                    {...rest}
                  />
                )}
              />
              <div className={styles.icon}>
                <img alt="" src="/images/content/icon-upload.svg" />
              </div>
              <div className={styles.format}>{t('create_form.upload_text')}</div>
            </div>
          </>
        )}
      </div>
    )
  }, [control, format, img, preview, setValue, t])

  const handleApply = (data: UploadFileForm) => {
    onSubmit(data)
    nextStep()
  }
  const onError = () => {
    if (!img) {
      message.error({
        content: t('create_form.uploadChooseFile'),
        className: styles.errorMessage,
      })
    }
    if ((format === 'VIDEO' || format === 'AUDIO') && !cover) {
      message.error({
        content: t('create_form.uploadChooseFileCover'),
        className: styles.errorMessage,
      })
    }
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleApply, onError)}>
      {(format === 'VIDEO' || format === 'AUDIO') && renderCover()}
      {renderImg()}
      <div className={styles.buttonsRow}>
        {lastStep && (
          <button
            className={classNames('button-outlined-gradient', styles.backButton)}
            type="button"
            onClick={() => lastStep()}>
            {t('back')}
          </button>
        )}
        <button className={classNames('button-gradient', styles.nextbutton)} type="submit">
          {t('next')}
        </button>
      </div>
    </form>
  )
}

export default UploadFileStep
