import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as LoginUser} from '../../assets/img/icons/login.svg'
import Button from '../Button'
import Modal from '../Modal'

import LogInOptions from './components/LogInOptions/LogInOptions'

import styles from './LogIn.module.scss'

interface ILogIn {
  className?: string
  withIcon?: boolean
}

interface ILoginModalProps {
  modalVisible: boolean
  showModal: (value: boolean) => void
}

export const LoginModal = ({modalVisible, showModal}: ILoginModalProps) => (
  <Modal
    outerClassName={styles.outerContainer}
    containerClassName={styles.modalContainer}
    visible={modalVisible}
    onClose={() => showModal(false)}>
    <LogInOptions onClose={() => showModal(false)} />
  </Modal>
)

const LogIn: React.FC<ILogIn> = ({className, withIcon}) => {
  const history = useHistory()
  const {t} = useTranslation()
  const [modalLogInVisible, showModalLogIn] = useState<boolean>(false)
  return (
    <>
      <Button
        className={classNames('button-small', styles.loginButton, className)}
        onClick={() => {
          if (process.env.REACT_APP_SEQUENCE_ACTIVE === 'true') showModalLogIn(true)
          else history.push('/connect-wallet')
        }}>
        {withIcon && <LoginUser className={styles.loginLogo} />}
        <span className={styles.loginText}>{t('logIn.title')}</span>
      </Button>
      <LoginModal modalVisible={modalLogInVisible} showModal={showModalLogIn} />
    </>
  )
}

export default LogIn
