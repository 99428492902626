import React, {useCallback, useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import Icon from '../Icon'

import styles from './Modal.module.scss'

const Modal: React.FC<any> = ({
  outerClassName,
  containerClassName,
  visible,
  onClose,
  closeButtonHidden,
  closeOnRight,
  children,
}) => {
  const escFunction = useCallback(
    e => {
      if (onClose && e.keyCode === 27) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const scrollRef = useRef(null)

  return createPortal(
    visible && (
      <div className={styles.modal} ref={scrollRef}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={() => {}}>
            <div className={cn(styles.container, containerClassName)}>
              {children}
              {!closeButtonHidden && (
                <button
                  type="button"
                  className={cn(styles.close, {
                    [styles.onRight]: closeOnRight,
                  })}
                  onClick={onClose}>
                  <Icon name="close" size="14" />
                </button>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body,
  )
}

export default Modal
