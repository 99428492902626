import useDarkMode from 'use-dark-mode'

// import {LOCAL_STORAGE} from '../constants/constants'
// import myLocalStorage from '../utils/myLocalStorage'

interface IImage {
  className?: string
  src: string
  srcDark?: string
  srcSet?: string
  srcSetDark?: string
  alt: string
}

const Image: React.FC<IImage> = ({className, src, srcSetDark, srcSet, alt}) => {
  const darkMode = useDarkMode(false)

  return (
    <img
      className={className}
      srcSet={
        // darkMode.value && !!myLocalStorage.get(LOCAL_STORAGE.DARK_MODE)
        darkMode.value ? srcSetDark || src : srcSet || src
      }
      src={src}
      alt={alt}
    />
  )
}

export default Image
