import {useEffect, useState} from 'react'
import classNames from 'classnames'
import {t} from 'i18next'

import {storeApi} from '../../services/api'
import {ISearchResponse} from '../../types'
import Icon from '../Icon'

import SearchResult from './SearchResult/SearchResult'

import styles from './Searchbar.module.scss'

const iSearchResponse: ISearchResponse = {
  collections: [],
  tokens: [],
  users: [],
}

interface ISearchbarProps {
  className?: string
}

const Searchbar: React.FC<ISearchbarProps> = ({className}) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchTime, setSearchTime] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [searchResponse, setSearchResponse] = useState<ISearchResponse>(iSearchResponse)

  useEffect(() => {
    if (!searchValue) {
      setSearchTime(null)
      return
    }

    if (searchTime) {
      clearTimeout(searchTime)
      setSearchTime(setTimeout(() => onChangeSearch(searchValue), 1000))
    } else {
      setSearchTime(onChangeSearch(searchValue))
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const onChangeSearch = async (value: string) => {
    setLoading(true)
    try {
      const response = await storeApi.quickSearch({text: value})
      setSearchResponse(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const focusOutSearch = (event: any) => {
    event.preventDefault()
    document?.getElementById('inputSearch')?.blur()
  }

  const focusInputSearch = () => {
    document?.getElementById('inputSearch')?.focus()
  }

  return (
    <button
      className={styles.container}
      onClick={() => focusInputSearch()}
      onKeyDown={() => {}}
      type="button">
      <div className={classNames(styles.searchContainer, className)} role="searchbox" tabIndex={-1}>
        <div className={styles.searchBox}>
          <div className={styles.searchIcon}>
            <Icon name="search" size="24" />
          </div>
          <input
            id="inputSearch"
            tabIndex={0}
            type="text"
            className={styles.inputSearch}
            placeholder={t('searchBar.placeholder')}
            value={searchValue}
            autoComplete="off"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={event => {
              if (event.code === 'Escape') focusOutSearch(event)
              else if (event.code === 'Delete') setSearchValue('')
            }}
          />
          {!!searchValue && (
            <button className={styles.cleanIcon} type="button" onClick={() => setSearchValue('')}>
              <Icon className={styles.icon} name="close" size="14" />
            </button>
          )}
        </div>
        {!!searchValue && (
          <SearchResult
            className={styles.results}
            searchResponse={searchResponse}
            searching={loading}
          />
        )}
      </div>
    </button>
  )
}

export default Searchbar
